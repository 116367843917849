.ic-run {
    font-size: 1rem; /* Adjust size as needed */
    margin-left: 0.5rem; /* Space between text and icon */
  }
  .runner-container {
    width: 100%;
    overflow: hidden;
    background: linear-gradient(to bottom, #d8d8d8 41%, #7c8282 100%);
    padding: 0rem;
    height: 4rem;
    position: relative;
    border-radius: 40px;
    filter: drop-shadow(0px 2px 4px black);
    margin-top: 0px;
    margin-bottom: 135px;
}
  
  .runner-content {
    display: inline-block;
    white-space: nowrap; /* Keep text in one line */
    animation: scroll 15s linear infinite; /* Smooth scroll animation */
  }
  
  .runner-content span {
    font-size: 17px;
    color: #0c0c0c;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 0px;
}
.ic-run {
    width: 4rem;
    height: auto;
    margin-left: 0rem;
    margin-right: 6rem;
    margin-bottom: 7px;
}
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%); /* Move left by 50% of the container */
    }
  }
  
  /* Mobile screen adjustments */
  @media only screen and (max-width: 767px) {
    .runner-content span {
      font-size: 16px;
      letter-spacing: 1px;
    }
    .runner-container {
      padding: 0.5rem;
    }
    .runner-container {
      width: 100%;
      overflow: hidden;
      background: linear-gradient(to bottom, #d8d8d8 41%, #7c8282 100%);
      padding: 0rem;
      height: 4rem;
      position: relative;
      border-radius: 40px;
      filter: drop-shadow(0px 2px 4px black);
      margin-top: 0px;
      margin-bottom: 79px;
  }
  }
  
  /* Tablet screen adjustments */
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .runner-content span {
      font-size: 20px;
    }
    .runner-container {
      padding: 0.75rem;
    }
  }
  