@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Dancing+Script:wght@400..700&display=swap');



.video-wrapper {
  position: relative;
  width: 100%;
  height: 20rem;
}

.video-wrapper video {
  width: 100%;
  height: 20rem;
  object-fit: fill;
  padding-bottom: 0;
  margin: 0;
}

.controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-card:hover .controls {
  opacity: 1;
}

.play-pause-icon {
  font-size: 50px;
  color: white;
}

.story-section {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 0rem 2rem;
  background: linear-gradient(90deg, #ffffff 81%, #e7e5e5 55%);
  margin-top: 1rem;
}
.full-story{
  background: linear-gradient(90deg, #ffffff 81%, #e7e5e5 55%);
} 
  /* Left side (75%) */
  .ll-s {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ll-s h2 {
    text-align: center;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 1rem;
    font-weight: 700;
    font-family: junge;
}
.head-pack {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #333;
  text-transform: uppercase;
  padding: 1rem;
  padding-top: 0rem;
  padding-bottom: 4rem;
  font-family: junge;
}


  
  .ll-s p {
    text-align: left;
    font-size: 1.2rem;
    color: #000000;
    margin-bottom: 2rem;
   
}
.p1{
  font-size: 20px !important;
  margin-bottom: 0 !important;
  font-family: sans-serif;
  text-align: left !important;
  font-weight: 500;
}
.p2 {
  font-size: 18px !important;
  margin-bottom: 0 !important;
  font-family: sans-serif;
  font-weight: 700;
  text-align: left !important;
}
.p3{
  font-size: 15px !important;
  font-family: "Courgette", cursive;
  margin-bottom: 0 !important;
  text-align: left !important;
}
.tt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 8px;
}

.ll-s .pp {
  text-align: left;
  font-size: 1.3rem;
  font-family: sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #000000;
  margin-bottom: 0rem;
  width: 100%;
  line-height: 1.6;
  letter-spacing: 2px;
}
  /* Grid of cards */
  .cards-grid-s {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  
  .card-s {
    background-color: #ffffff00;
  
    border-radius: 10px;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    transition: transform 0.3s ease;
  }
  
  .card-s:hover {
    transform: translateY(1px);
  }
  .card-content-s {
    width: 60%;
}
.card-content-s h3 {
    font-size: 1.3rem;
    color: #020202;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
}
  
.card-content-s p {
    font-size: 14px;
    color: #000000;
}
  
.icon-s {
    font-size: 4rem;
    color: #004e4e;
    width: 40%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(1px 2px 3px white);
}
  
  /* Right side (25%) */
  .rr-s {
    width: 39%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  
  .rr-s img {
    width: 100%;
    border-top-left-radius: 10em;
    border-top-right-radius: 10em;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    height: 29rem;
    filter: drop-shadow(2px 4px 6px black);
}








.email-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    background-color: #f8f9fa00;
}
  
.contact-info {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 793px;
    margin-bottom: 0rem;
    gap: 10rem;
}
  
.email-info, .call-info {
  display: flex;
  gap: 20px;
  align-items: center;
  background: #e7e5e5d6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 11px;
  margin: 0px 0rem;
  /* flex: 1 1; */
  width: auto;
  justify-content: center;
}
  
  .contact-text {
    flex: 1;
  }
  
  .contact-text h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #000000;
    text-transform: uppercase;
    font-weight: bold;
}
  .contact-text p {
    font-size: 1rem;
    color: #000000;
    text-transform: lowercase;
    font-family: sans-serif;
    font-weight: 500;
}
  
  .contact-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact-button {
    text-align: center;
  }
  
  .contact-button button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #004e4e;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-transform: uppercase;
}
  
  .contact-button button:hover {
    background-color:#04a0a0;
  }
  




















  /* General Styles */
.gallery-container {
    padding: 20px;
    background-color:#ffffff;
    text-align: center;
  }
  
  .gallery-heading {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
    font-weight: bold;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  /* Card Styles */
  .gallery-card {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    filter: drop-shadow(2px 4px 6px black);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .gallery-card img {
    width: 100%;
    height: 20rem;
    display: block;
    transition: transform 0.5s ease;
    object-fit: cover;
}
  
 
  
  /* Hover Effects */
  .gallery-card:hover {
    transform: translateY(1px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .gallery-card:hover img {
    transform: scale(1.05);
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .gallery-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .gallery-grid {
      grid-template-columns: 1fr;
    }
  
    .gallery-heading {
      font-size: 1.8rem;
    }
  }
  










  @media only screen and (max-width: 767px){
    .story-section {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      padding: 1rem;
      background: linear-gradient(0deg, #ffffff 78%, #e7e5e5 55%);
      margin-top: 1rem;
      flex-direction: column-reverse;
  }
  .head-pack {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0px;
    color: #333;
    text-transform: uppercase;
    padding: 0rem;
    padding-top: 0rem;
    padding-bottom: 4rem;
    font-family: junge;
}
  .rr-s {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
.ll-s {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ll-s .pp {
  text-align: justify;
  font-size: 1.1rem;
 
  font-weight: 400;
  font-style: normal;
  color: #000000;
  margin-bottom: 0rem;
  width: 100%;
  line-height: 1.6;
  letter-spacing: 2px;
}
.ll-s h2 {
  text-align: center;
  font-size: 1.7rem;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 1rem;
  font-weight: 700;
}
.p1 {
  font-size: 20px !important;
  margin-bottom: 0 !important;
 
  text-align: left !important;
  color: black !important;
  padding-top: 0rem;
}
.email-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color:#f8f9fa;
  gap: 27px;
}
.email-info, .call-info {
  display: flex;
  gap: 15px;
  background: #e7e5e5d6;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 11px;
  margin: 0px 0rem;
  /* flex: 1 1; */
  width: auto;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
}
.contact-info {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* max-width: 775px; */
  margin-bottom: 0rem;
  gap: 1rem;
  flex-direction: column;
}
.tt {
  display: flex;
  width: 100%;
  padding-top: 8px;
  flex-direction: column;
  align-items: flex-start;
  /* position: relative; */
}
.contact-text h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
}

  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .ll-s .pp {
      text-align: left;
      font-size: 1.1rem;
      
      font-weight: 400;
      font-style: normal;
      color: #000000;
      margin-bottom: 0rem;
      width: 100%;
      line-height: 1.6;
      letter-spacing: 2px;
  }
  .rr-s img {
    width: 100%;
    border-top-left-radius: 10em;
    border-top-right-radius: 10em;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    height: 27rem;
    filter: drop-shadow(2px 4px 6px black);
}
.contact-info {
  display: flex;
  justify-content: flex-start;
  width: 56%;
  /* max-width: 810px; */
  margin-bottom: 0rem;
  gap: 3rem;
}
.rr-s {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.email-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa00;
}
  }










  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .contact-info {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      /* max-width: 810px; */
      margin-bottom: 0rem;
      gap: 3rem;
  }
  }
  