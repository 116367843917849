.incre-p {
    display: flex;
  }
  .left-incre {
    flex: 40% 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding: 20px;
    background-color: #c7c7c7;
}
.llp{
  text-decoration: none;
}
.fullcc {
  display: flex;
  padding: 1rem;
  align-items: center;
}
.divt {
  padding: 10px;
  background-color: #005050;
  position: relative;
  right: 70px;
  border-top-right-radius: 55px;
  border-bottom-right-radius: 55px;
  filter: drop-shadow(0px 0px 3px white);
}
.incre-p {
  margin-top: 0px;
  margin-bottom: 1rem;
  font-size: 22px;
  color: #000000;
}
.incre-h {
  font-size: 4.7rem;
  text-align: left;
  color: #004e4e;
  font-family: junge;
  font-weight: 600;
}
.incre-h5 {
    text-align: left;
    margin: 0;
    padding: 0;
    font-size: 22px;
    color: #000000;
}
  
  .right-incre {
    flex: 60%;
  }
  
  .right-incre img {
    width: 100%;
    height: 100%;
    object-fit: cover;
   
}
.text-bottle {
  padding: 2rem;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}
  










  .i-count {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 21px;
}
  
  .count-item {
    flex: 1;
    padding: 20px;
    border: 1px solid #090909; /* Optional: Add border for better visibility */
    border-radius: 8px; /* Optional: Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
    text-align: center; /* Center text inside each div */
  }
  
  .count-item h2 {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #005050;
}
  
.count-item h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #005050;
  font-weight: 700;
  font-family: junge;
}
  
.count-item p {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #005050;
  text-transform: none;
}
  













.incre-f {
    padding: 0px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .incre-f-heading {
    text-align: left;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0px;
    color: #333;
    text-transform: uppercase;
    padding: 1rem;
    font-family: junge;
}
  
  .incre-f-feature {
    display: inline-block;
    background-color: #1a1919;
    padding: 20px;
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    text-align: left;
}
.incre-f-feature-title {
  font-size: 2rem;
  color: #ffffff;
  margin: 10px 0;
  letter-spacing: 1px;
  font-style: oblique;
  padding-left: 10px;
  font-family: junge;
}
.incre-f-feature-img {
  width: 30rem;
  height: 19rem;
  border-radius: 8px;
  border: 2px solid white;
}
  
.incre-f-feature-description {
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.5;
  margin-top: 1rem;
  font-family: sans-serif;
  letter-spacing: 1px;
  font-weight: 100;
}
  



@media only screen and (max-width: 767px){
  .incre-p {
    display: flex;
    flex-direction: column;
}
.right-incre img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 3px solid;
  border-top: 0px solid;
  border-right: 3px solid;
}
.incre-h {
  font-size: 3rem;
  text-align: left;
}
.i-count {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 21px;
  flex-direction: column;
}
.incre-f-feature-img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 2px solid white;
}
.incre-f-heading {
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #333;
  text-transform: uppercase;
  padding: 1rem;
}
.divt {
  padding: 10px;
  background-color: #005050;
  position: relative;
  right: 0px;
  border-bottom-left-radius: 55px;
  border-bottom-right-radius: 55px;
  filter: drop-shadow(0px 0px 3px white);
  border-top-right-radius: 0px;
}
.incre-f-feature-img {
  width: 100%;
  height: auto;
  border-radius: 0px;
  border: 2px solid white;
}
.incre-f-feature {
  display: inline-block;
  background-color: #1a1919;
  padding: 0px;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: left;
}
.incre-f-feature-description {
  font-size: 0.7rem;
  color: #ffffff;
  line-height: 1.5;
  margin-top: 1rem;
  text-align: justify;
}
.fullcc {
  display: flex;
  padding: 1rem;
  align-items: center;
  flex-direction: column;
}
}


@media only screen and (min-width: 768px) and (max-width: 1024px){
  .incre-p {
    display: flex;
    flex-direction: column;
}
.right-incre img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 3px solid;
  border-top: 0px solid;
  border-right: 3px solid;
}
.divt {
  padding: 10px;
  background-color: #005050;
  position: relative;
  right: 8px;
  border-top-right-radius: 55px;
  border-bottom-right-radius: 55px;
  filter: drop-shadow(0px 0px 3px white);
  /* width: 48rem; */
  height: 22rem;
  display: flex;
  align-items: center;
}
.incre-f-feature-description {
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.5;
  margin-top: 1rem;
}
.incre-f-feature-img {
  width: 20rem;
  height: 15rem;
  border-radius: 8px;
  border: 2px solid white;
}
}



