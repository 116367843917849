.runner-containerr {
  width: 100%;
  overflow: hidden;
  background: linear-gradient(to bottom, #d8d8d8 41%, #004e4e 100%);
  padding: 1rem;
  position: relative;
}

.runner-contentt {
  display: inline-block;
  white-space: nowrap; /* Keep text in one line */
  animation: scrolll 15s linear infinite; /* Smooth scroll animation */
}

.runner-contentt span {
  font-size: 24px;
  color: #004e4e;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 0px; /* Space between each item */
}
.ic-runn {
  width: 1.5rem;
  height: auto;
 margin-left: 1rem;
 margin-right: 1rem;
  margin-bottom: 7px;
}
@keyframes scrolll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move left by 50% of the container */
  }
}

/* Mobile screen adjustments */
@media only screen and (max-width: 767px) {
  .runner-contentt span {
    font-size: 16px;
    letter-spacing: 1px;
  }
  .runner-containerr {
    padding: 0.5rem;
  }
}

/* Tablet screen adjustments */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .runner-contentt span {
    font-size: 20px;
  }
  .runner-containerr {
    padding: 0.75rem;
  }
}
