.category-bg{
    background-image: url('../../Assests/pngfl.webp'); 
    background-size: cover; 
    background-position: center; 
    background-color: #d7dad8;
}

.error-text2 {
    color: rgb(209 60 60);
    font-size: 10px !important;
    margin-bottom: 0;
    margin: 0 !important;
}
.logo-p-c{
    width: 100px;
    height: auto;
}




@media only screen and (max-width: 767px){
    .j-p-o {
        font-size: 2rem !important;
      }
      .j-p-o2 {
        font-size: 10px !important;
      }
      .j-p-p{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
}

@media only screen and (min-width: 768px) and (max-width: 1023px){

}