.full-icon-div {
    display: flex;
    width: 100%;
    height: 40vh;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
  
.left-icon-div {
    width: 40%;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid #676767;
    border-top-left-radius: 5em;
    /* filter: drop-shadow(2px 4px 6px black); */
}
  
.left-icon-div img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    filter: drop-shadow(0px 0px 0px #004e4e);
}
  
.right-icon-div {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  background: linear-gradient(91deg, #dddbdb 30%, #fffefe 100%);
}
  
.icon-text {
  font-size: 3.2rem;
  font-weight: bold;
  color: #004e4e;
  word-wrap: break-word;
  text-align: left;
  letter-spacing: 1px;
  filter: drop-shadow(0px 0px 1px white);
  font-family: junge;
}
  
.full-text-icon-div {
  margin-top: 0px;
  padding: 1rem;
  background-color: #e7e5e5;
}
  
.text-description {
  font-size: 1.2rem;
  font-weight: lighter;
  color: black;
  letter-spacing: 1px;
  animation: fadeIn 1s ease-in-out;
  text-align: justify;
  line-height: 1.5;
  font-family: sans-serif;
  font-weight: 500;
}
  /* Additional styling for responsiveness */
  @media (max-width: 768px) {
    .full-icon-div {
      flex-direction: column;
      height: auto;
    }
  
    .left-icon-div,
    .right-icon-div {
      width: 100%;
    }
  
    .icon-text {
      font-size: 2rem;
    }
    .right-icon-div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      text-align: center;
      background: linear-gradient(91deg, #dddbdb 30%, #fffefe 100%);
  }
  .icon-text {
    font-size: 2rem;
    font-weight: bold;
    color: #004e4e;
    word-wrap: break-word;
    text-align: center;
    letter-spacing: 1px;
    filter: drop-shadow(0px 0px 1px white);
}
  
    .text-description {
      font-size: 0.9rem;
    }
  }
  






  
@media only screen and (max-width: 767px){
    
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
  .text-description {
    font-size: 1.2rem;
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
  .full-icon-div {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
.left-icon-div {
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid #676767;
  border-top-left-radius: 5em;
  /* filter: drop-shadow(2px 4px 6px black); */
}
  .icon-text {
    font-size: 2.2rem;
    font-weight: bold;
    color: #004e4e;
    word-wrap: break-word;
    text-align: left;
    letter-spacing: 1px;
    filter: drop-shadow(0px 0px 1px white);
    font-family: junge;
}
.right-icon-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 17px;
  text-align: center;
  background: linear-gradient(91deg, #dddbdb 30%, #fffefe 100%);
}
}


@media only screen and (min-width: 768px) and (max-width: 1024px){
  .left-icon-div {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid #676767;
    border-top-left-radius: 5em;
    /* filter: drop-shadow(2px 4px 6px black); */
  }
}
