@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .error {
    color:rgb(255 81 81);
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 1px;
}
div:where(.swal2-container) div:where(.swal2-popup) {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 17em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fcfcfc;
  color: #000000;
  font-family: inherit;
  font-size: 1rem;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #4d4239;
  color: #fff;
  font-size: 1em;
}
.submission-error {
  color:rgb(255 81 81);
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1px;
}
.name-input {
  width: 70%;
  padding: 6px;
  margin: 5px 0;
  border: 0px solid black;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

.modal-main {
  width: 49rem;
  height: 28rem;
  position: absolute;
  top: 16%;
}
 
.modal-close-button {
  position: absolute;
  top: 0%;
  right: 9%;
  background: no-repeat;
  border: none;
  color: #000000;
  z-index: 10000;
  font-size: 29px;
  font-weight: bold;
  cursor: pointer;
  /* border-radius: 50%; */
  /* width: 45px; */
  /* height: 56px; */
}
  .modal-close-button:hover {
    color: #004e4e;
    filter: drop-shadow(2px 4px 14px white);
  }
  
  .display-block {
    display: flex;
  }
  
  .display-none {
    display: none;
  }
  
  .modal-img {
    width: 100%;
    height: auto;
  }
  .modal-text {
    font-size: 10px;
    font-weight: 200;
    margin: 0;
    padding: 10px;
    font-family: "Space Grotesk";
  }
  
  .submit-button-f {
    appearance: none;
    background-color: #ffffff;
    border-radius: 40em;
    border-style: none;
    box-shadow: #adcfff 0 -12px 6px inset;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: -0.24px;
    margin: 0;
    outline: none;
    padding: 0.5rem 1rem;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 10rem;
}
  .butt-f{
    display: flex;
    justify-content: center;
  }
  .submit-button-f:hover {
    background-color: #ffc229;
    box-shadow: #ff6314 0 -6px 8px inset;
    transform: scale(1.125);
  }
  
  .submit-button-f:active {
    transform: scale(1.025);
  }
  
  @media (min-width: 768px) {
    .modal-button {
      font-size: 1rem;
      padding: 0.75rem 2rem;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .modal-main {
      position: relative;
      background: transparent;
      padding: 0px;
      border-radius: 8px;
      
      text-align: center;
      left: 0;
      top: 0;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
  }
   
    .banner-container {
      position: fixed !important;
      top: 10% !important;
      left: 0% !important;
      width: 100% !important;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
      transition: opacity 0.5s ease-in-out;
  }
  .card-b {
    display: flex;
    width: 98%;
    height:auto !important;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    align-content: center;
    align-items: center !important;
    flex-direction: row;
}
.left-divv {
  width: 55%;
  padding: 6px !important;
  gap: 0.3rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
.right-divv {
  width: 55% !important;
  height: auto;
}
.left-divv h1 {
  margin: 0 !important;
  font-size: 10px ;
  font-family: 'Cormorant', serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left !important;
}
.left-divv p {
 
  font-size: 10px !important;
  text-align: center !important;
}
.j-p-o {
  font-size: 2rem !important;
}
.email-input {
  width: 100%;
  padding: 0px !important;
  margin: 0px 0 !important;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  font-size: 10px !important;
  text-align: center !important;
}
.checkbox-label {
  display: flex;
  align-items: flex-start !important;
  margin: 5px 0 !important;
  font-size: 10px !important;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: left;
  color: black;
}
.modal-close-button {
  position: absolute;
  top: -3%;
  right: 4.5%;
  background: no-repeat;
  border: none;
  color: #000000;
  z-index: 10000;
  font-size: 29px;
  font-weight: bold;
  cursor: pointer;
  /* border-radius: 50%; */
  /* width: 45px; */
  /* height: 56px; */
}
.submit-button2 {
  background-color: #004e4e;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 18px;
  cursor: pointer;
  font-size: 12px !important;
}
    
  }
  

  /* Modal background */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal container */
  .modal-container {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 500px;
    text-align: center;
    z-index: 1001;
    position: relative;
  }
  
  .modal-heading {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
}
  
  /* Modal form */
  .modal-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 6px;
  }
  
  /* Form group */
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .form-group label {
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #ffffff;
    font-size: 13px;
}
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    color: white;
    border-radius: 5px;
    font-size: 1rem;
    background: #00000000;
    transition: border-color 0.3s;
    height: 30px;
}
  
  .form-group input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Submit button */
  .submit-button {
    padding: 0.75rem 1.5rem;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .modal-main {
      position: relative;
      background: linear-gradient(to bottom left, #00526400 40%, #00526400 100%);
      padding: 0px;
      border-radius: 8px;
      width: 90%;
      
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      transform: translateX(0);
      animation: zigzag 0.5s ease-in-out forwards;
      animation-play-state: paused;
      border: 1px solid white;
      left: 0% !important;
      top: 5% !important;
  }
  .left-divv {
    padding: 20px;
    gap: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
  .card-b {
    display: flex;
    width: 100% !important;
    height: 100% !important;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    align-content: center;
    align-items: center;
}
  .banner-container {
    position: fixed;
    top: -150% !important;
    left: -81% !important;
    width: 45rem;
    height: 26rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: opacity 0.5s ease-in-out;
}
.modal-close-button {
  position: absolute;
  top: -24px !important;
  right: 0;
  background: no-repeat;
  border: none;
  color: #000000;
  z-index: 10000;
  font-size: 48px;
  font-weight: bold;
  cursor: pointer;
  /* border-radius: 50%; */
  /* width: 45px; */
  /* height: 56px; */
}
.right-divv {
  width: 52% !important;
  height: auto;
}
  }



























  /* Banner.css */

  .banner-container {
    position: fixed;
    top: 19%;
    left: 22%;
    width: 45rem;
    height: 26rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: opacity 0.5s ease-in-out;
}
  
  .banner-container.show {
    opacity: 1;
  }
  
  .banner-container.hide {
    opacity: 0;
    pointer-events: none;
  }
  
  .card-b {
    display: flex;
    width: 92%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    align-content: center;
    align-items: center;
}

.left-divv {
  padding: 5px;
  gap: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  justify-content: center;
}
  
.left-divv h1 {
  margin: 0;
  font-size: 16px;
  font-family: 'Cormorant', serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
  
.left-divv p {
  margin: 8px 0;
  font-size: 16px;
  text-align: center;
  margin-bottom: -3px;
  font-weight: 700;
  width: 80%;
}
  .j-p {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
}
.j-p-o{
  font-size: 3.5rem !important;
}
  
.email-input {
  width: 70%;
  padding: 6px;
  margin: 5px 0;
  border: 0px solid black;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}
  
  .checkbox-label {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: black;
}
  
  .checkbox-input {
    margin-right: 10px;
  }
  
  .submit-button2 {
    background-color: #004e4e;
    color: white;
    border: none;
    padding: 5px 20px;
    border-radius: 18px;
    cursor: pointer;
    font-size: 15px;
}
  
  .submit-button2:hover {
    background-color: #089292;
  }
  
  .right-divv {
    width: 55%;
    height: auto;
}
  
  .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .close-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  