/* -------------------- Base Styles -------------------- */

/* Shop Container */
.shop-container {
  display: flex;
  margin: 20px;
  background-color: #ffffff;
  margin-top: 75px;
}

/* Sidebar Styles - Desktop */
.filter-sidebar {
  width: 300px;
  padding: 20px;
  border-right: 2px solid #e0e0e0;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #005050 #f9f9f9; /* For Firefox */
}

.filter-sidebar.collapsed {
  width: 0;
  padding: 0;
  overflow: hidden;
}

/* Custom Scrollbar for Webkit Browsers */
.filter-sidebar::-webkit-scrollbar {
  width: 8px;
}

.filter-sidebar::-webkit-scrollbar-thumb {
  background: #005050;
  border-radius: 4px;
}

.filter-sidebar::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Sidebar Content Styles */
.filter-sidebar h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  background-color: #494949;
  padding: 3px;
  border-radius: 25px;
  font-family: junge;
}

.filter-sidebar h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #000000;
  text-transform: uppercase;
  font-weight: 700;
  font-family: junge;
}

.price-range-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 1em;
  color: #555;
  gap: 10rem !important;
}

/* Price Range Styles */
.price-range-info span {
  font-weight: bold;
}

/* Range Input Styles */
input[type="range"] {
  width: 100%;
  background: transparent; /* Make track color apply only to pseudo-elements */
  height: 4px; /* Thin track height */
  border-radius: 5px; /* Rounded track corners */
  outline: none;
  cursor: pointer;
  position: relative;
  margin: 9px 0; /* Margin for spacing */
}

/* Track Color */
input[type="range"]::-webkit-slider-runnable-track {
  background: #005050; /* Track color for Webkit browsers */
  height: 4px; /* Thin track height */
  border-radius: 5px; /* Rounded track corners */
}

input[type="range"]::-moz-range-track {
  background: #005050; /* Track color for Firefox */
  height: 4px; /* Thin track height */
  border-radius: 5px; /* Rounded track corners */
}

input[type="range"]::-ms-track {
  background: transparent; /* Hide track for IE */
  border-color: transparent;
  color: transparent;
  height: 4px; /* Thin track height */
}

input[type="range"]::-ms-fill-lower {
  background: #005050; /* Lower fill color for IE */
  border-radius: 5px; /* Rounded track corners */
}

input[type="range"]::-ms-fill-upper {
  background: #e0e0e0; /* Upper fill color for IE */
  border-radius: 5px; /* Rounded track corners */
}

/* Thumb Color */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  width: 16px; /* Thumb width */
  height: 16px; /* Thumb height */
  border-radius: 50%; /* Rounded thumb */
  background: #005050; /* Thumb color */
  cursor: pointer;
  position: relative;
  z-index: 1; /* Ensure thumb is above the track */
  margin-top: -6px; /* Adjust to center thumb on track */
  transition: background 0.3s ease;
}

input[type="range"]::-moz-range-thumb {
  width: 16px; /* Thumb width */
  height: 16px; /* Thumb height */
  border-radius: 50%; /* Rounded thumb */
  background: #005050; /* Thumb color */
  cursor: pointer;
  transition: background 0.3s ease;
}

input[type="range"]::-ms-thumb {
  width: 16px; /* Thumb width */
  height: 16px; /* Thumb height */
  border-radius: 50%; /* Rounded thumb */
  background: #005050; /* Thumb color */
  cursor: pointer;
  transition: background 0.3s ease;
}

/* Hover Effect for Thumb */
input[type="range"]:hover::-webkit-slider-thumb {
  background: #003d3d; /* Darker thumb color on hover */
}

input[type="range"]:hover::-moz-range-thumb {
  background: #003d3d; /* Darker thumb color on hover */
}

input[type="range"]:hover::-ms-thumb {
  background: #003d3d; /* Darker thumb color on hover */
}

/* Track Styling for Better Aesthetics */
input[type="range"]::-webkit-slider-runnable-track {
  border: 1px solid #003d3d; /* Slight border to enhance the look */
}

input[type="range"]::-moz-range-track {
  border: 1px solid #003d3d; /* Slight border to enhance the look */
}

input[type="range"]::-ms-track {
  border: 1px solid #003d3d; /* Slight border to enhance the look */
}

/* Checkboxes Styles */
.filter-sidebar div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filter-sidebar .pi {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column;
}

.filter-sidebar input[type="checkbox"] {
  margin-right: 10px;
}

.filter-sidebar label {
  font-size: 12px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
  text-transform: uppercase;
  font-weight: 500;
  font-family: sans-serif;
}

.filter-sidebar label:hover {
  color: #003d3d;
}

.cb {
  margin: 5px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  color: #8d393c;
}

.cbl {
  text-decoration: none;
  color: #000000;
}

.cbl:hover {
  color: #005050;
}

.card-btn {
  display: flex;
}

/* Product Grid Styles */
.product-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
  
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 1.6rem;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #005050 #f9f9f9; /* For Firefox */
}
.product-gridd {
  flex: 1 1;
  display: grid;
 
  height: calc(100vh - 0px);
  overflow-y: auto;
  box-sizing: border-box;

  scrollbar-width: thin;
  scrollbar-color: #005050 #f9f9f9;
}
.fls {
  display: flex;
  justify-content: space-between;
}
/* Custom Scrollbar for Webkit Browsers */
.product-gridd::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.product-gridd::-webkit-scrollbar-thumb {
  background: #005050; /* Color of the scrollbar handle */
  border-radius: 4px; /* Rounded corners for the handle */
}

.product-gridd::-webkit-scrollbar-track {
  background: #f9f9f9; /* Color of the scrollbar track */
}

.product-card1 {
  background-color: #fff;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  padding: 10px;
  height: 42rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.169);
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.img-div {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 5px;
  perspective: 1000px;
}

.product-image1 {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /* Start zoomed in */
  object-fit: cover;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3)); /* Initial light shadow */
  
  transition: transform 0.5s ease, filter 0.5s ease; /* Smooth transitions */
}

/* Hover effect */
.img-div:hover .product-image1 {
  
  filter: drop-shadow(4px 8px 12px rgba(0, 0, 0, 0.5)); /* Increase shadow depth on hover */
}

.st-d {
  font-size: 14px;
  color: #005050;
}
.title-p1 {
  font-size: 1.2em;
  margin: 5px;
  color: #000000;
  text-transform: capitalize;
  margin-top: 18px;
  font-weight: 500;
  font-family: sans-serif;
  height: 4.5rem;
  text-align: center;
}

.title-p2 {
  font-size: 12px;
  margin-bottom: 0;
  margin: 5px;
  font-weight: 400;
}
.description1 {
  margin: 5px;
  color: #222121;
  font-size: 13.5px;
  font-family: sans-serif;
  font-weight: 500;
  text-align: center;
  height: 8rem;
}

.fl-p {
    display: flex;
    justify-content: center;
    margin: 5px;
    color: #333;
    margin-top: 0;
    margin-bottom: 0;
    font-family: sans-serif;
    gap: 1rem;
}
.fl-p1 {
  display: flex;
  justify-content: space-between;
  margin: 5px;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  font-family: sans-serif;
  gap: 1rem;
}
.price1 {
  font-size: 1em;
  font-weight: bold;
  color: black;
}

.p-size1 {
  color: #000000;
  margin-bottom: 0;
  font-size: 1em;
  font-weight: bold;
}

.emi1 {
  margin: 5px;
  color: #007bff;
}

.star1 {
  margin: 0px;
  color: #f39c12;
  text-align: center;
}

.star2 {
  color: #ff0000;
  font-size: 19px;
}

.view-product1 {
  display: block;
  width: calc(100% - 20px);
  margin: 5px;
  padding: 7px;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-product1:hover {
  background-color: #004e4e;
  color: white;
}

.btn-ic {
  width: 1.5rem;
  height: auto;
}

/* -------------------- Consolidated Mobile Styles -------------------- */

@media screen and (max-width: 768px) {
  /* Sidebar Hidden by Default */
  .filter-sidebar {
    position: fixed; /* Fixed relative to the viewport */
    top: 0;
    padding: 12px;
    left: 0;
    width: 80%; /* Adjust width as needed */
    max-width: 300px; /* Optional: Set a max-width for better control */
    height: 100vh; /* Full viewport height */
    background-color: #ffffff; /* Ensure background covers content */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3); /* Enhanced shadow for depth */
    transform: translateX(-100%); /* Hide sidebar off-screen to the left */
    transition: transform 0.3s ease-in-out; /* Smooth transition */
    z-index: 25; /* Ensure sidebar is above other elements */
    display: block; /* Ensure the sidebar is block-level for positioning */
  }

  .filter-sidebar.active {
    transform: translateX(0);
    margin-top: 71px;
}

  /* Overlay Styles */
  .overlay {
    position: fixed; /* Cover the entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 20; /* Below the sidebar but above other content */
    opacity: 0; /* Initially invisible */
    visibility: hidden; /* Hide the overlay */
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; /* Smooth transition */
  }

  /* Overlay Visible When Sidebar is Active */
  .overlay.active {
    opacity: 1; /* Make the overlay visible */
    visibility: visible; /* Show the overlay */
  }

  /* Sidebar Toggle Button */
  .sidebar-toggle {
    display: block;
    position: fixed;
    top: 85px;
    left: 8px;
    z-index: 35;
    background: #005050;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 20px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
    transition: background 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

  .sidebar-toggle:hover {
    background: #003d3d; /* Darker shade on hover */
  }

  /* Update the product grid layout for mobile */
  .product-grid {
    grid-template-columns: 1fr;
    padding: 10px;
    gap: 20px;
    margin-top: 48px;
}

.product-card1 {
  height: 42.12rem;
  margin-bottom: 20px;
}

  .img-div {
    height: auto; /* Smaller image container */
  }

  .product-image1 {
    height: 100%;
    transform: none; /* Remove rotation and scaling on mobile */
    filter: none; /* Remove drop shadow if not desired on mobile */
  }

  .title-p1 {
    font-size: 1.2em;
    height: 4.5rem;
}

  .title-p2 {
    font-size: 0.9em;
  }

  .description1 {
    font-size: 12px;
    height: 6rem;
  }

  .fl-p {
    
    align-items: flex-start; /* Align items to the start */
  }

  .price1 {
    font-size: 1em;
  }

  .emi1 {
    font-size: 0.9em;
    color: #007bff; /* Ensure consistent color */
  }

  /* Adjust Filter Sidebar for smaller screens */
  .filter-sidebar.collapsed {
    display: block; /* Ensure it's block-level */
    width: 34%; /* Adjust width if needed */
  }

  .filter-sidebar h3 {
    font-size: 1em;
    margin-bottom: 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    background-color: #494949;
    padding: 10px;
    border-radius: 25px;
    width: 11rem;
    margin-left: 41px;
}

  /* Adjust Checkbox Styles for Mobile */
  .filter-sidebar div {
    margin-bottom: 10px; /* Space between checkboxes */
  }

  .filter-sidebar input[type="checkbox"] {
    width: 16px; /* Larger checkboxes for touch */
    height: 16px;
    margin-right: 10px;
    cursor: pointer;
  }

  .filter-sidebar label {
    font-size: 14px; /* Increased font size for readability */
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease;
    text-transform: uppercase;
    font-weight: 500;
  }

  .filter-sidebar label:hover {
    color: #003d3d;
  }

  /* Ensure Buttons Are Appropriately Sized */
  .view-product1 {
    width: calc(100% - 20px); /* Ensure full-width buttons */
    margin: 5px;
    padding: 10px;
    font-size: 13px;
  }

  .view-product1:hover {
    background-color: #004e4e;
    color: white;
  }
}

/* -------------------- Tablet Styles (Optional) -------------------- */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .product-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 equal columns */
    padding: 10px;
    gap: 20px;
    margin-top: 48px;
}
.product-card1 {
  height: 50rem;
  margin-bottom: 20px;
}
.filter-sidebar.collapsed {
  display: block; /* Ensure it's block-level */
  width: 34%; /* Adjust width if needed */
}
.filter-sidebar {
  position: fixed;
  top: 42px;
  padding: 12px;
  left: 0;
  width: 80%;
  max-width: 300px;
  height: 100vh;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 25;
  display: block;
}
.overlay.active {
  opacity: 0;
  visibility: visible;
}
.title-p1 {
  font-size: 1.2em;
  height: 4.5rem;
}
.description1 {
  font-size: 15px;
  height: 8rem;
}
.sidebar-toggle {
  display: block;
  position: fixed;
  top: 126px;
  left: 8px;
  z-index: 35;
  background: #005050;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 20px;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.filter-sidebar.active {
  transform: translateX(0);
  margin-top: 71px;
}
.filter-sidebar h3 {
  font-size: 1em;
  margin-bottom: 20px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  background-color: #494949;
  padding: 10px;
  border-radius: 25px;
  width: 11rem;
  margin-left: 41px;
}
.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px;
  gap: 20px;
  margin-top: 102px;
}

}
















