.full-faq {
  background-color: #dad9d7;
  height: auto;
  position: relative;
}
.leave {
  width: 219px;
  height: auto;
  /* filter: drop-shadow(0px 0px 2px black); */
  position: absolute;
  top: -8px;
  left: -27px;
  transform: rotate(281deg);
}
.leave-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
  
}
.faq-sectionn {
  width: 80%;
  margin: 50px auto;
  padding: 0 0;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 25px;
  margin-bottom: 10px;
}
  
.faq-heading {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0px;
  letter-spacing: 1px;
  /* font-family: "Play", sans-serif; */
  font-family: junge;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 3rem;
  
}
  /* FAQ Container */
  .faq-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .faq-item {
    background-color:#a4b09c;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 10px;
    position: relative;
    overflow: hidden;
  }
  
  .faq-item:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
  }
  
  .faq-item.active {
    background-color:#005050;
  }
  
  /* Question Styling */
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-question-text {
    font-size: 1.1rem;
    color: #444;
    font-weight: 600;
    transition: color 0.3s ease;
    text-transform: uppercase;
}
  
  .faq-item.active .faq-question-text {
    color: #ffffff;
  }
  
  .faq-arrow {
    font-size: 1.3rem;
    color: #000000;
    transition: transform 0.3s ease;
    transform: rotate(90deg);
}
  
  .faq-item.active .faq-arrow {
    transform: rotate(180deg);
  }
  
  /* Answer Styling */
  .faq-answer {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s ease;
    margin-top: 10px;
    padding-left: 0px;
  }
  
  .faq-answer.show {
    max-height: 150px;
    opacity: 1;
  }
  
  .faq-answer-text {
    font-size: 16px;
    color: #f2f0f0;
    line-height: 1.6;
    font-weight: 300;
    font-style: italic;
    letter-spacing: 1px;
}
  
  .faq-item:hover .faq-question-text {
    color: #ffffff;
    filter: drop-shadow(0px 0px 1px black);
}
  
  .faq-item:hover .faq-arrow {
    color: #fdfdfd;
  }
  
  .faq-answer.show {
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }
  
  @media (max-width: 768px) {
    .faq-heading {
        font-size: 1.5rem;
        margin-bottom: 0;
        padding: 1rem;
    }
  
    .faq-question-text {
      font-size: 0.8rem;
    }
  
    .faq-answer-text {
      font-size: 0.7rem;
    }
  }
  




  @media only screen and (max-width: 767px){
    .faq-sectionn {
        width: 100%;
        margin: 0 auto;
        padding: 7px 7px;
        font-family: 'Roboto', sans-serif;
    }
    .leave {
      width: 82px;
      height: auto;
      /* filter: drop-shadow(0px 0px 2px black); */
      position: absolute;
      top: -2px;
      left: -27px;
      transform: rotate(281deg);
  }
  .faq-sectionn {
    margin-top: 0px;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .leave {
      width: 143px;
      height: auto;
      /* filter: drop-shadow(0px 0px 2px black); */
      position: absolute;
      top: -8px;
      left: -27px;
      transform: rotate(281deg);
  }
  
  }