.review2-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    padding: 0px;
    width: 100%;
    padding-bottom: 30px;
}
  
  .slider-wrapper {
    width: 100%;
    overflow: hidden;
  }
  
  .slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  .review2-container .slider {
    font-family: junge;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    cursor: pointer;
}
  
  .review-card2 {
    flex: 1 0 33.33%; /* 3 cards visible at once */
    padding: 10px;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
  }

  
  .f-s {
    margin-bottom: 10px;
  }
  
  .review-card2 h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .review-card2 p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
  }
  
  .review-card2 img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    margin-top: 15px;
  }
  
  /* Updated Navigation Buttons */
  .prev-button,
  .next-button {
    background-color: transparent;
    border: none;
    font-size: 30px;
    color: #333;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    padding: 10px;
    transition: color 0.3s ease;
  }
  
  .prev-button:hover,
  .next-button:hover {
    color: #ff7a18;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }
  
  .dots-container {
    text-align: center;
    position: absolute;
    top: 15.6rem;
}
  
  .dot {
    display: inline-block;
    height: 12px;
    width: 12px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dot.active {
    background-color: #717171;
  }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .review-card2 {
      flex: 1 0 50%; /* Show 2 cards on medium screens */
    }
  }
  
  @media (max-width: 600px) {
    .review-card2 {
      flex: 1 0 100%; /* Show 1 card on small screens */
    }
  
    .prev-button,
    .next-button {
      font-size: 24px;
      padding: 8px;
    }
  
    .dot {
      height: 10px;
      width: 10px;
    }
  }
  























  /* Slick CSS for styling */
.slick-slider {
    margin: 0 auto;
    width: 100%;;
  }
  
  .review-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
}
  
  .slider-wrapper {
    overflow: hidden;
  }
  
  .top-slider, .bottom-slider {
    width: 100%;
    display: flex !important;
    gap: 1rem;
  }
  
  .review-card2 {
    display: flex !important;
    flex-direction: row;
    align-items: flex-start;
    background: rgb(242, 242, 242);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 0 0px;
    border-radius: 0px;
    border-right: 2px solid black;
    text-align: center;
    height: 15rem;
}
.f-s {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
  
  .review-card2 img {
    width: 100px;
    height: auto;
  }
  
  .review-card2 h4 {
    font-size: 24px;
    margin-top: 10px;
    
    color: rgb(0, 0, 0);
    font-weight: 800;
}
  
  .review-card2 p {
    font-size: 14px;
    font-family: sans-serif;
    color: #000000;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: left;
  }
  
  /* Animation for alternating slider movement */
  .slick-track {
    animation-timing-function: linear;
  }
  
  .slick-active {
    opacity: 1;
  }
  
  .slick-active .review-card {
    transform: scale(1.1);
  }
  .slider-wrapper {
 
    margin-bottom: 20px; /* Adjust space between the two sliders */
  }
  .review-head {
    text-align: center;
    font-weight: 700;
    padding: 2rem;
    text-transform: uppercase;
    font-family: junge;
}
  
  
  @keyframes scrollLeft {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
  
  @keyframes scrollRight {
    0% { transform: translateX(0); }
    100% { transform: translateX(100%); }
  }
  



  @media only screen and (max-width: 767px){
    .review-head {
      text-align: center;
      font-weight: 700;
      padding: 1.5rem;
      text-transform: uppercase;
  }
  .review-card2 p {
    font-size: 9px;
    color: #000000;
    text-align: left;
}
.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  flex-direction: column;
}
.review-card2 {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  background: rgb(242, 242, 242);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 6px;
  margin: 0 10px;
  border-radius: 0px;
  border-right:none;
  text-align: center;
  height: 14.5rem;
  justify-content: center;
}
.dots-container {
  text-align: center;
  position: absolute;
  top: 78%;
}
.review-card2 h4 {
  font-size: 11px;
  margin-top: 10px;
  color: rgb(0, 0, 0);
}
.review-card2 img {
  width: 35px;
  height: auto;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .review-card2 {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      background: rgb(242, 242, 242);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 4px;
      margin: 0 10px;
      border-radius: 0px;
      border-right: 2px solid black;
      text-align: center;
  }
  .review-card2 p {
    font-size: 19px;
    color: #000000;
    text-align: left;
}
.review-card2 h4 {
  font-size: 25px;
  margin-top: 10px;
  color: rgb(0, 0, 0);
}
  }