@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.plan-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 35px;
  background-color:#f0f0f0;
}
.post-a {
  margin-top: calc(73px);
}

.plan {
  background-color: #353535;
  color: #f1f1f1;
  padding: 40px;
  text-align: center;
  
  width: 330px;
  height: 400px;
  position: relative;
}

.plan h2 {
  font-size: 46px;
  margin-bottom: 30px;
  text-transform: capitalize;
  font-weight: bold;
  z-index: 2;
  margin-top: 22px;
  color: #004d4d;
  position: relative;
  font-family: junge;
  /* font-style: italic; */
  letter-spacing: 2px;
}
.bp{
  margin-bottom: 40px;
  font-size: 20px !important;
  text-align: justify;
  position: relative;
  z-index: 2;
}
.plan2 {
  background-color: #353535;
  color: #f1f1f1;
  padding: 29px;
  text-align: center;
  width: 330px;
  height: 400px;
  position: relative;
}

.plan2 p {
  margin-bottom: 40px;
  font-size: 2rem;
  position: relative;
  z-index: 2;
  color: #005050;
  font-family: sans-serif;
  font-weight: 500;
}
.plan-p {
  font-family: junge;
  font-weight: bold;
  font-size: 30px !important;
  color: #004d4d;
  margin-bottom: 10px !important;
  z-index: 99;
  position: relative;
  letter-spacing: 2px;
}

.price {
  font-size: 17px;
  font-weight: 800;
}

.consultation {
  position: absolute;
  bottom: 59px;
  width: 100%;
  left: 0;
  font-size: 1.5rem;
}

.premium {
  position: relative; /* Required for positioning the overlay */
  background-color: #4949490f; /* Default background color */
  background-image: none; /* No background image initially */
  border-top-left-radius: 10em !important;
  border-radius: 50% / 0%;
  border: 1px solid #282827;
  transition: background 0.5s ease, background-color 0.5s ease; /* Smooth transitions */
  overflow: hidden; /* Prevents overlay from exceeding boundaries */
  z-index: 1;
}

.premium:hover {
  background-image: url('../../Assests/img.png'); /* Background image appears on hover */
  background-size: cover; /* Ensure the image covers the entire area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

.premium::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 0, 0); /* Initially no overlay */
  transition: background-color 0.5s ease; /* Smooth overlay transition */
  border-top-left-radius: inherit;
  border-radius: inherit;
}

.premium:hover::before {
  background-color:rgba(224, 221, 218, 0.693); /* Yellow overlay appears on hover */
}




.advanced {
  position: relative; /* For positioning the overlay */
  background-color: rgba(134, 134, 134, 0.9); /* Default background color */
  background-image: url('../../Assests/img2.png'); /* Background image */
  background-size: cover; /* Ensure the image covers the entire area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  border: 1px solid #292929;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 50rem;
  overflow: hidden; /* Prevent overlay from exceeding boundaries */
  z-index: 1; /* Ensure text stays on top */
}

.advanced::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:#dddbdbd6;
  z-index: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}



.basic {
  background-color: #4949490f;
  border: 1px solid #004f4f;
  border-top-right-radius: 10em !important;
  border-radius: 50% / 0%;
}










.post-a-image {
  width: 100%;
  height: 87vh;
  display: block;
  margin-bottom: 20px;
}
  .post-a-heading {
    text-align: left;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0px;
    color: #333;
    text-transform: uppercase;
    padding: 1rem;
}
  

  /* Flex container for the content */
  .post-a-content {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 2%; /* Padding for spacing */
    margin-bottom: 0px;
  }
  
  .left-a {
    flex: 0 0 40%;
    text-align: center;
    padding: 20px;
    background-color: #a94448 !important;
}
  
  .left-a h2 {
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: #ffffff; /* Adjust color as needed */
  }
  
  .left-a h4 {
    font-size: 2.2rem;
    color: #fffdfd; /* Subtle color for h4 */
    margin-bottom: 10px;
  }
  
  /* Right-a div (60% width) */
  .right-a {
    flex: 0 0 60%;
    padding: 20px;
    color: #555;
  }
  
  .right-a p {
    font-size: 1.28rem;
    line-height: 1.9;
    text-align: justify;
    margin-bottom: 15px;
    color: #333;
}
  
  /* Additional styling */
  .post-a-content > div {
    background-color: #cdcdcd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
  }
  
  @media (max-width: 768px) {
    /* Responsive adjustments */
    .post-a-content {
      flex-direction: column;
      padding: 0 5%;
    }
  
    .left-a, .right-a {
      flex: 100%;
      padding: 10px;
      margin-bottom: 20px;
    }
  }
  









  .l-story-container {
    position: relative;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .l-story-img {
    width: 70%;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    opacity: 0.8;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1;
    animation: glow-border 4s linear infinite;
  }
  
  /* Glowing border animation */
  @keyframes glow-border {
    0% {
      box-shadow: 0 0 5px 5px #77856d;
    }
    50% {
      box-shadow: 0 0 20px 10px #a4b09c;
    }
    100% {
      box-shadow: 0 0 5px 5px #77856d;
    }
  }
  
  .l-s {
    z-index: 2;
    text-align: center;
    color: white;
    animation: fadeInUp 1s ease-in-out;
    padding: 2rem;
    width: 100%;
    background-color: #a4b09c;
}
  
.l-s h1 {
    font-size: 4rem;
    margin-bottom: 10px;
    letter-spacing: 2px;
    color: black;
    text-transform: uppercase;
    animation: slideIn 0.7s ease-in-out;
    font-weight: 700;
}
  
.l-s h5 {
    font-size: 1.5rem;
    font-weight: lighter;
    color: black;
    letter-spacing: 1px;
    animation: fadeIn 1s ease-in-out;
    text-align: center;
    line-height: 1.5;
}
  

  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-50px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  








  .o-story {
    padding: 0px 0px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .o-story .content {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    
    background-color:#004e4e;
    background-image: url('../../Assests/pngfl.webp'); /* Replace with the path to your image */
    background-size: cover; /* Ensures the image covers the entire element */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    padding-bottom: 2.5rem;
}
.h-st {
  text-align: left;
  font-size: 39px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 0rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-family: junge;
}
  
.o-story h2 {
    text-align: left;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0px;
    color: #fefdfd;
    text-transform: uppercase;
    padding: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
  

  

  .o-story p:last-of-type {
    margin-bottom: 0;
  }
  
  .o-story p {
    text-align: justify;
    font-size: 1.2rem;
    font-weight: 500;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    animation: fadeIn 1s ease-in-out;
    text-align: left;
    line-height: 1.5;
    font-family: sans-serif;
}
  
  @media (max-width: 768px) {
    .o-story h2 {
      font-size: 28px;
    }
  
    .o-story p {
      font-size: 16px;
      text-align: justify;
    }
  }
  









  .n-incre {
    text-align: center;
    margin-top: 0px;
    padding-bottom: 2rem;
}
  
.n-incre h2 {
  text-align: left;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #333;
  text-transform: uppercase;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-family: junge;
}
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    padding: 0 50px;
  }
  
  .n-incre-card {
    transition: transform 0.3s ease-in-out;
    background-color: #2e2e2e;
    padding: 0px;
    transition: border-radius 0.5s ease; /* Adding smooth transition */
}
  
  .n-incre-card:hover {
    transform: translateY(0px);
    border-top-left-radius: 7em;
    border-top-right-radius: 7em;
  }
  
  .card-image {
    width: 100%;
    border-radius: 0px;
    object-fit: cover;
    height: 12rem;
    transition: border-radius 0.5s ease; /* Adding smooth transition */
}
.card-image:hover {
  border-top-left-radius: 7em;
  border-top-right-radius: 7em;
  border: 1px solid rgb(93, 93, 93);
}
.card-content {
  text-align: center;
  margin-top: 3px;
  padding: 8px;
}
  
.card-content h3 {
  font-size: 1.3rem;
  color: #fdfdfd;
  margin-bottom: 10px;
  height: 3rem;
  font-family: junge;
}
  
.card-content p {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
  height: 4.5rem;
  overflow: hidden;
  margin-bottom: 0;
  font-family: sans-serif;
  font-weight: 500;
}
  
  .read-more4 {
    font-size: 1rem;
    font-weight: bold;
    color: #f6f7f8;
    border: none;
    background: none;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.3s ease;
  }
  
  .read-more4:hover {
    color: #d2d3d3;
  }
  








  .logo-s {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f500;
    padding: 0px;
    margin-top: 1rem;
}
  
  .l-ll {
    flex: 0 0 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    background-color: #a4b09c;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
   
  }
 
  
  .l-ll h1 {
    font-size: 3.5rem;
    margin-bottom: 10px;
    letter-spacing: 2px;
    color: black;
    text-transform: uppercase;
    animation: slideIn 0.7s ease-in-out;
    font-weight: 700;
}
  
  .l-ll h5 {
    font-size: 1.5rem;
    font-weight: lighter;
    color: black;
    letter-spacing: 1px;
    animation: fadeIn 1s ease-in-out;
    text-align: center;
    line-height: 1.5;
  }
  
 
  
  .r-ll {
    flex: 0 0 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 10px;
    margin-left: 0px;
  }
  .l-rr{
    height: 15rem;
  }
  .l-rr {
    height: 34rem;
    width: 12rem;
}
  
.r-ll img {
  width: 90%;
  object-fit: fill;
  border-radius: 9px;
  box-shadow: 0 1px 8px #005050;
}
  
  @media (max-width: 768px) {
   
  
  
   
  }
  








/* Step-A Section */
.step-a {
    padding: 2rem;
    background-color: #cdcdcd;
    text-align: center;
}

.step-a h2 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0px;
    color: #333;
    text-transform: uppercase;
    padding: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.step-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
  justify-content: center;
}

.step-card {
  background: #005050;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.step-media {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
}

.step-image, .step-video {
    width: 100%;
    border-radius: 12px;
    transition: opacity 0.5s ease-in-out;
}

.step-image {
    display: block;
}



.step-image.hide, .step-video.show {
    opacity: 1;
}

.step-image.show, .step-video.hide {
    opacity: 1;
}

.step-content {
    margin-top: 0.5rem;
}

.step-content h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
    color: white;
    font-weight: bold;
}

.step-content p {
    margin: 0;
    color: #fdfdfd;
}

.arrow-animation {
    position: absolute;
    bottom: 15px;
    left: 97%;
    transform: rotate(270deg);
    width: 24px;
    height: 24px;
    /* transform: revert-layer; */
}

.arrow {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #007bff;
    animation: bounce 1s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}




@media only screen and (max-width: 767px){
  .post-a-image {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
    object-fit: fill;
}
.post-a-heading {
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #333;
  text-transform: uppercase;
  padding: 1rem;
}
.left-a h2 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #ffffff;
}
.l-ll h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: black;
  text-transform: uppercase;
  animation: slideIn 0.7s ease-in-out;
  font-weight: 700;
}
.l-ll {
  flex: 0 0 79%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  background-color: #a4b09c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 6px;
  border-radius: 10px;
}
.r-ll img {
  width: 100%;
  object-fit: fill;
  border-radius: 9px;
  box-shadow: 0 1px 8px #005050;
}

.l-rr {
  height: 15rem;
  width: 12rem;
}
.post-a {
  margin-top: calc(80px);
}
.logo-s {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #f5f5f500;
  padding: 0px;
  flex-direction: row;
}
.l-ll h5 {
  font-size: 1.2rem;
  font-weight: lighter;
  color: black;
  letter-spacing: 1px;
  animation: fadeIn 1s ease-in-out;
  text-align: center;
  line-height: 1.5;
}
.r-ll {
  flex: 0 0 20%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 4px;
  margin-left: 0px;
}
.l-s {
  z-index: 2;
  text-align: center;
  color: white;
  animation: fadeInUp 1s ease-in-out;
  padding: 3px;
  width: 100%;
  background-color: #a4b09c;
}
.step-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(353px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
  justify-content: center;
}
.h-st {
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #fff4f4;
  text-transform: uppercase;
  padding: 0rem;
}
.l-s h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: black;
  text-transform: uppercase;
  animation: slideIn 0.7s ease-in-out;
  font-weight: 700;
}
.l-s h5 {
  font-size: 1.1rem;
  font-weight: lighter;
  color: black;
  letter-spacing: 1px;
  animation: fadeIn 1s ease-in-out;
  text-align: center;
  line-height: 1.5;
}
.n-incre h2 {
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #333;
  text-transform: uppercase;
  padding: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.n-incre {
  text-align: center;
  margin-top: 0px;
  padding-bottom: 1rem;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 0 5px;
}
.step-a {
  padding: 0rem;
  background-color: #f5f5f5;
  text-align: center;
}
.step-a h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #333;
  text-transform: uppercase;
  padding: 1rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.read-more {
  font-size: 0.8rem;
  font-weight: bold;
  color: #004e4e;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s ease;
}
.plan-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 14px;
  flex-direction: column;
  background-color: #f0f0f0;
}
.bp {
  margin-bottom: 40px;
  font-size: 16px !important;
  text-align: justify;
  position: relative;
  z-index: 2;
}
.plan2 {
  background-color: #353535;
  color: #f1f1f1;
  padding: 13px;
  text-align: center;
  width: 100%;
  height: auto;
  position: relative;
}
.plan {
  background-color: #35353500;
  color: #f1f1f1;
  padding: 40px;
  text-align: center;
  width: 100%;
  height: auto;
  position: relative;
}
.card-content h3 {
  font-size: 1.3rem;
  color: #fdfdfd;
  margin-bottom: 10px;
  height: 4rem;
  font-family: junge;
}
.plan2 p {
  margin-bottom: 6px;
  font-size: 2rem;
  position: relative;
  z-index: 2;
  color: #005050;
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
  .post-a-image {
    width: 100%;
    height: 52vh;
    display: block;
    margin-bottom: 20px;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 5px;
}
.logo-s {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #f5f5f500;
  padding: 0px;
}
.post-a {
  margin-top: calc(112px);
}
.plan h2 {
  font-size: 24px;
  margin-bottom: 30px;
  text-transform: capitalize;
  font-weight: 700;
  z-index: 2;
  margin-top: 22px;
  color: #004d4d;
  position: relative;
  /* font-family: "Pacifico", cursive; */
  /* font-style: italic; */
  letter-spacing: 3px;
}
.plan-p {
  /* font-family: "Pacifico", cursive; */
  /* font-style: italic; */
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 21px !important;
  color: #004d4d;
  margin-bottom: 10px !important;
  z-index: 99;
  position: relative;
}
.bp {
  margin-bottom: 40px;
  font-size: 16px !important;
  text-align: justify;
  position: relative;
  z-index: 2;
  margin-bottom: 10px !important;
}
.advanced {
  position: relative;
  background-color: rgba(134, 134, 134, 0.9);
 
  border: 1px solid #292929;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 50rem;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.premium {
  position: relative;
  background-color: #4949490f;
  background-image: none;
  border-top-left-radius: 10em !important;
  border-radius: 50% / 0%;
  border: 1px solid #282827;
  transition: background 0.5s ease, background-color 0.5s ease;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
}