.disclaimer-section {
    padding: 40px;
    background-color: #f9f9f9;
    color: #333;
    padding-top: 6rem;
}
  
.disclaimer-content {
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
}
  
.disclaimer-title {
  font-size: 40px;
  color: #000000;
  margin-bottom: 20px;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 2px;
}
  .disclaimer-subtitle {
    font-size: 30px;
    color: #000000;
    margin-top: 30px;
    margin-bottom: 10px;
    text-transform: capitalize;
    letter-spacing: 1px;
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 2px;
    font-weight: 800;
}
  
  /* Section headings */
  .disclaimer-heading {
    font-size: 30px;
    color: #000000;
    margin-top: 30px;
    margin-bottom: 10px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: 800;
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 2px;
  }
  
  /* Paragraph text */
  .disclaimer-text {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
    font-family: sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
}
  /* Hyperlink styling */
  .disclaimer-link {
    color: #004e4e;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }
  
  /* Unordered lists */
  .disclaimer-list {
    margin-left: 20px;
    margin-bottom: 20px;
    list-style-type: disc; /* Ensures bullet points (dots) */
  }
  
  .disclaimer-list-item {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 10px;
    color: #555;
    font-family: sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
}
  
  /* Ordered lists */
  .disclaimer-list-ordered {
    margin-left: -14px;
    margin-bottom: 20px;
    list-style-type: disc;
}
  
.disclaimer-list-ordered li {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 10px;
    color: #555555;
    font-family: sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
}
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .disclaimer-content {
      padding: 15px;
    }
  
    .disclaimer-title {
      font-size: 24px;
    }
  
    .disclaimer-subtitle {
      font-size: 20px;
    }
  
    .disclaimer-heading {
      font-size: 18px;
    }
  
    .disclaimer-text,
    .disclaimer-list-item,
    .disclaimer-list-ordered li {
      font-size: 14px;
    }
    .disclaimer-section {
      padding: 15px;
      background-color: #f9f9f9;
      color: #333;
      padding-top: 6rem;
  }
  }
  




  @media only screen and (max-width: 767px){
    
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .disclaimer-section {
      padding: 15px;
      background-color: #f9f9f9;
      color: #333;
      padding-top: 8rem;
  }
  
  }









  