.slider {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .slide {
    position: absolute;
    width: 100%;
    height: 87vh;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    margin-top: calc(73px);
    background-color: white;
}
  .slide.active {
    opacity: 1;
    z-index: 1;
  }
  
  .overlayu {
    position: absolute;
    top: 52%;
    right: 58%;
    transform: translateY(-50%);
    color: white;
    text-align: left;
}
  
.overlayu h2 {
  font-size: 5rem;
  margin-bottom: 10px;
  width: 30rem;
  color: #ffffff;
  filter: drop-shadow(0px 0px 6px black);
}
  
.overlayu p {
  font-size: 2rem;
  color: rgb(255, 255, 255);
  filter: drop-shadow(0px 0px 6px rgb(0, 0, 0));
  width: 75%;
}
  
  @media (max-width: 768px) {
    .overlayu h2 {
      font-size: 2rem;
    }
  
    .overlayu p {
      font-size: 1rem;
    }
  }
  




  @media only screen and (max-width: 767px){
    .overlayu {
      position: absolute;
      top: 83%;
      right: 5%;
      transform: translateY(-50%);
      color: white;
      text-align: left;
  }
  .slider {
    position: relative;
    width: 100%;
    height: 16rem;
    overflow: hidden;
  }
  .overlayu {
    position: relative;
    top: 59%;
    left: 10px;
    width: 100%;
    height: 100%;
    background-color: rgba(202, 202, 202, 0);
    z-index: 9999;
    opacity: 1;
    /* visibility: hidden; */
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.overlayu h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  width: 9rem;
  color: #ffffff;
  filter: drop-shadow(0px 0px 6px black);
}
.overlayu p {
  width: 8rem;
  font-size: 1rem;
  color: rgb(255, 255, 255);
  filter: drop-shadow(0px 0px 6px rgb(0, 0, 0));
}
  
.slide {
  position: absolute;
  width: 100%;
  height: 70% !important;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  margin-top: calc(80px);
  background-color: white;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .overlayu {
      position: absolute;
      top: 42%;
  left: 5%;
      transform: translateY(-50%);
      color: white;
      text-align: left;
  }
  .overlayu h2 {
    font-size: 4rem;
    margin-bottom: 10px;
    width: 23rem;
    color: #ffffff;
    filter: drop-shadow(0px 0px 6px black);
}
.overlayu p {
  font-size: 2rem;
}
.slide {
  position: absolute;
  width: 100%;
  height: 30rem;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  margin-top: calc(112px);
  background-color: white;
}
.slider {
  position: relative;
  width: 100%;
  height: 36rem;
  overflow: hidden;
}
  }




