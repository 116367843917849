.point-section {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fffffffc;
    background-image: url('../../Assests/169896120_103306f3-a06a-4454-bee3-6c6dd184121e.jpg'); 
    background-size: cover;
    background-position: top;
    /* margin-top: 5.6%; */
     padding-top: 7.5rem;
    
}
  
.card-pp {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 88%;
    box-shadow: 1px 21px 36px rgba(0, 0, 0, 0.2);
}
  
.time-slots-container{
    display: flex
;
    gap: 8px;
    flex-direction: column;
}
  
.card-pp h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: rgb(0, 0, 0);
    margin-bottom: 2rem;
    font-family: junge;
}
  
.radio-group {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr); 
    
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.f-st {
    display: flex;
    align-items: center;
    gap: 3rem;
}  
.point-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    transition: transform 0.4s ease-in-out;
    gap: 10px;
}
  
  .point-item:hover {
    transform: translateY(-10px);
  }
  
  .point-item input[type="radio"] {
    margin-right: 10px;
    transform: scale(1.5);
    cursor: pointer;
  }
  
  .point-item span {
    font-size: 18px;
    color: #2d2c2c;
    margin-right: 5px;
    text-transform: uppercase;
    width: 50%;
}
  
  .point-img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #ddd;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .point-img:hover {
   
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .card-pp {
      width: 95%;
    }
  
    .point-item span {
        font-size: 16px;
        width: 9rem;
        margin-right: 0;
    }
    .point-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        transition: transform 0.4s ease-in-out;
        gap: 0px;
    }
    .point-img {
      width: 50px;
      height: 50px;
    }
  }
  













/* General Styles */
.contact-container {
    display: flex;
    height: auto;
    padding: 2rem;
    justify-content: center;
}

/* Calendar Section Styles */
.calendar-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

/* Calendar Grid for Date Selection */
.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 7 columns for each day of the week */
    gap: 10px;
    margin-right: 20px;
}

/* Individual Date Style */
.date {
    background-color: #f1f1f1;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

/* Selected Date Style */
.date.selected {
    background-color: #4caf50;
    color: white;
}

/* Time Slots Section */
.time-section-horizontal {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Individual Time Slot Style */
.time-slot {
    padding: 10px;
    background-color: #e0e0e0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Selected Time Slot Style */
.time-slot.selected {
    background-color: #4caf50;
    color: white;
}

/* Next Button Style (appears in place of selected time slot) */
.next-button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%; /* Matches width of the time slots */
    border-radius: 5px;
    text-align: center;
    transition: transform 0.3s ease;
}

/* Hover effect for Next Button */
.next-button:hover {
    transform: translateX(10px); /* Slide effect on hover */
}

/* Form Section Styles */
.form-container {
    display: flex;
    justify-content: space-between;
}

/* Form Section Layout */
.form-section {
    width: 50%;
}

.left-section {
    width: 35%;
    padding: 5px;
}

.selected-details {
    margin-bottom: 0px;
    display: flex;
    gap: 10px;
}

.social-icons {
    display: flex;
    gap: 10px;
    margin-top: 27px;
}

/* Style for Individual Social Icons */
.icon {
    font-size: 37px;
    cursor: pointer;
    color: #a4b09c;
}
.fff2 {
    display: flex;
    justify-content: space-between;
}
/* Flexbox Column Layout for Dates and Times */
.d-f {
    display: flex;
    flex-direction: column;
}

/* Call Us Section with Icon */
.call-us {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    font-weight: bold;
}
.form-container {
    width: 100%;
    margin: 0 auto;
    padding: 0rem;
    background-color: #004c4c00; /* Background color */
   
   
}

.h2-u {
    font-size: 2rem;
    font-weight: 700;
    color: white;
    font-family: junge;
    letter-spacing: 1px;
}
.h2-u-span{
    text-transform: capitalize;
   
}

/* Form Header */
.form-container h2 {
    color: #ffffff;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    text-align: left;
}
.left-section p {
    color: white;
    font-size: 20px;
}
/* Form Sections */
.form-section {
    margin-bottom: 1.5rem;
}

/* Form Labels */
form label {
    display: block;
    font-weight: 600;
    color: #f7f6f6;
    margin-bottom: 0.5rem;
    font-size: 19px;
}

/* Form Inputs and Textareas */
form input[type="text"],
form input[type="email"],
form input[type="tel"],
form input[type="number"],
form textarea {
    width: 100%;
    padding: 12px;
    color: white;
    margin-bottom: 1rem;
    border: 2px solid #ffffff;
    border-radius: 8px;
    font-family: system-ui;
    font-size: 14px;
    background-color: #c1ccb916;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.date.disabled-sunday {
    color: gray;          /* Optional: change text color */
    pointer-events: none;  /* Disable pointer events to prevent clicks */
    cursor: not-allowed;   /* Show 'not-allowed' cursor */
  }


  
/* Focus State for Inputs */
form input[type="text"]:focus,
form input[type="email"]:focus,
form input[type="tel"]:focus,
form input[type="number"]:focus,
form textarea:focus {
    border-color: #4caf50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
    outline: none;
}

.submit-buttonc {
    background-color: #038a8a;
    color: white;
    padding: 12px;
    border: none;
    cursor: pointer;
    width: 10rem;
    border-radius: 8px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
    font-weight: 700;
}

.submit-buttonc:hover {
    background-color:#026666;
    transform: translateY(-2px);
}

.submit-buttonc:active {
    transform: translateY(0);
}

/* Additional Styling for Textareas */
form textarea {
    resize: vertical;
    min-height: 100px;
}

/* Error Messages */
.error-message {
    color: #e74c3c;
    font-size: 0.875rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
}

/* Success Message */
.success-message {
    color: #2ecc71;
    font-size: 0.875rem;
    margin-top: 1rem;
}



/* General Date Style */
.date {
    background-color: #f1f1f1;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

/* Available Date Style (Green) */
.date.available {
    background-color: #4caf50; /* Green for available dates */
    color: white;
}

/* Sunday Date Style (Red, Non-clickable) */
.date.sunday {
    background-color: #ff6961; /* Red for Sundays */
    color: white;
    pointer-events: none; /* Disable clicks on Sundays */
}

/* Selected Date Style */
.date.selected {
    background-color: #4caf50;
    color: white;
}

/* Hover effect for available dates */
.date.available:hover {
    background-color: #45a049;
}





.error {
    color: #ffbf43;
    font-size: 15px;
    margin-bottom: 0;
}
  
  .date.selected {
    background-color: #4CAF50;
    color: white;
  }
  
  .date.disabled {
    color: lightgray;
    pointer-events: none; /* Disable past dates */
  }
  


  .contact-container {
    display: flex;
    height: auto;
    padding: 2rem;
    gap: 15px;
    justify-content: space-around;
    background-color: #004e4e;
    padding-top: 2.5rem;
    margin: 5rem;
    background-image: url('../../Assests/pngfl.webp'); /* Background image */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 3rem;
}

/* Calendar Section Styles */
.calendar-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 35px;
}

/* Calendar Grid for Date Selection */
.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 7 columns for each day of the week */
    gap: 10px;
    margin-right: 0px;
}

/* Individual Date Style */
.date {
    background-color: #f1f1f1;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

/* Available Date Style (Green) */
.date.available {
    background-color: #4caf50; /* Green for available dates */
    color: white;
}





/* Year Display Style */
.year-display {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }
  
  /* Days of the Week Styling */
  .days-of-week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .day-name {
    padding: 10px;
    background-color: #005050;
    color: #e0e0e0;
}
  
  /* Other styles remain the same */
  
  .date {
    background-color: #a4b09c;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}
  
.date.selected {
    background-color: #005050;
    color: white;
    border: 1px solid white;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
   
}
  
.next-buttonn {
    background-color: #005050;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-weight: 800;
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
    border: 1px solid white;
    position: relative;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    background-size: 200% 100%;
    animation: movingGlow 3s linear infinite;
}

@keyframes movingGlow {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.ne-ic{
    width: 12px;
    height: auto;
}  
  .next-buttonn:hover {
      transform: translateX(10px);
  }
  
  /* Time Slot Styling */
  .time-slot {
      padding: 10px;
      background-color: #e0e0e0;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  }
  
  .time-slot.selected {
    background-color: #005050;
    color: white;
    border: 1px solid white;
    margin-bottom: 8px;
    position: relative;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
}


  .submit-buttonc:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .time-slot.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
    color: white;
    background-color: #a4b09c;
}







  @media only screen and (max-width: 767px){
    .contact-container {
        display: flex;
        height: auto;
        padding: 1rem;
        gap: 15px;
        margin: 1rem;
        justify-content: center;
        flex-direction: column;
    }
    .left-section {
        width: 100%;
        padding: 5px;
    }
    .calendar-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 35px;
    }
    .h2-u {
        font-size: 1.4rem;
        
        font-weight: 700;
        color: white;
    }
   
    .calendar {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 8px;
        margin-right: 0px;
    }
    .day-name {
        padding: 3px;
        background-color: #005050;
        color: #e0e0e0;
    }
    .time-section-horizontal {
        display: flex
;
        flex-direction: column;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .time-slot-wrapper {
        gap: 10px;
        display: flex
    ;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }
    .time-slot {
        padding: 10px;
        background-color: #e0e0e0;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 13px;
    }
    .time-slots-container {
        display: flex
    ;
        gap: 8px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .next-buttonn {
        background-color: #005050;
        color: white;
        padding: 10px;
        border-radius: 5px;
        font-weight: 800;
        width: 16rem;
        cursor: pointer;
        transition: transform 0.3s ease;
        border: 1px solid white;
        position: relative;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
        background-size: 200% 100%;
        animation: movingGlow 3s linear infinite;
    }
    .time-slot.selected {
        background-color: #005050;
        color: white;
        border: 1px solid white;
        margin-bottom: 0px;
        position: relative;
        box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
    }
    .but-dd{
        display: flex;
    }
    .form-container {
        width: 100%;
        margin: 0 auto;
        padding: 0rem;
        background-color: #a2ac9b00;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    
    .form-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
    }
    .form-section {
        width: 100%;
        padding: 8px;
    }
    .fff2 {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }
    .radio-group {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .point-section {
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fffffffc;
        background-image: url('../../Assests/169896120_103306f3-a06a-4454-bee3-6c6dd184121e.jpg');
        background-size: cover;
        background-position: top;
        /* margin-top: 5.6%; */
        padding-top: 6rem;
    }
    .card-pp h2 {
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 700;
        color: rgb(0, 0, 0);
        margin-bottom: 2rem;
    }
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .form-container {
        width: 100%;
        margin: 0 auto;
        padding: 0rem;
        background-color: #f9f9f900;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    .left-section {
        width: 50%;
        padding: 5px;
    }
    .form-section {
        width: 50%;
    }
    .time-slot {
        padding: 7px;
        background-color: #e0e0e0;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 15px;
    }
    .fff2 {
        display: flex;
        justify-content: space-between;
        gap: 9px;
    }
    .point-section {
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fffffffc;
        background-image: url('../../Assests/169896120_103306f3-a06a-4454-bee3-6c6dd184121e.jpg');
        background-size: cover;
        background-position: top;
        /* margin-top: 5.6%; */
        padding-bottom: 1rem;
        padding-top: 10.5rem;
    }
    .card-pp {
        width: 97%;
    }

    .contact-container {
        display: flex;
        height: auto;
        padding: 1rem;
        gap: 15px;
        justify-content: space-around;
        background-color: #004e4e;
        padding-top: 2.5rem;
        margin: 1rem;
        background-image: url('../../Assests/pngfl.webp');
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-top: 3rem;
    }
  }
 