.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 16px;
  margin-top: 20px;
}

.video-card {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}
.vd{
 width: 100%;
}






.blog-section {
  padding: 2rem;
  text-align: center;
  background-color: #dad9d7;
  padding-top: 6.5rem;
}
.h2-bd {
  font-size: 3.5rem !important;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
  /* Poster Image */
  .poster-image {
    width: 90%;
    margin: 0 auto;
    display: block;
    border-radius: 10px;
  }
  
  /* Toggle Buttons */
  .blog-toggle {
    margin: 2rem 0;
  }
  
  .blog-toggle button {
    margin: 0 0.5rem;
    padding: 0.6rem 1.2rem;
    cursor: pointer;
    background-color: #004e4e;
    border: none;
    border-radius: 20px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 1rem;
    color: white;
}
  
  .blog-toggle button:hover {
    background-color: #016363;
    color: white;
}
  
  .blog-toggle button.active {
    background-color: #a4b09c;
    color: rgb(0, 0, 0);
  }
  
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns, each taking an equal fraction of available space */
    gap: 1.5rem;
    margin-top: 2rem;
}

.detail-image1 {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1.5rem;
}
.blog-content p{
  font-family: sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 20px;
}
.blog-content li {
  font-family: sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 20px;
  list-style: inside;
}
.fullbg {
  padding-top: 2.5rem;
  background-image: url('../../Assests/pngfl.webp');
  background-size: cover;
  background-position: center;
  background-color: #003a3a;
  margin-bottom: 2rem;
}
  
ul {
  list-style-type: none; /* Removes bullets */
            
}
.blog-card {
  background: #d8d8d8;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* border: 1px solid; */
  filter: drop-shadow(0px 5px 4px black);
}
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .blog-card-image {
    overflow: hidden;
    margin-bottom: -7px;
}
  
  .blog-card-image img {
    width: 100%;
    height: 15rem;
    object-fit: fill;
    transition: transform 0.5s ease-in-out; /* Smooth transition for zoom and rotate */
  }
  
  .blog-card-image:hover img {
    transform: scale(1.1) rotate(5deg); /* Zoom in and slightly rotate image on hover */
  }
  
  
  
  /* Blog Card Content */
  .blog-card-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 50%;
  }
  
  .blog-card-content h4 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    flex-grow: 1;
    font-weight: 800;
    font-family: junge;
}
  
.blog-card-content p {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #5e5e5e;
}
  
  .blog-card-content button {
    align-self: flex-start;
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .blog-card-content button:hover {
    background-color: #0056b3;
  }
  

  .social-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #a7b29f;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
    margin-bottom: 45px;
    border: 1px solid #004c4c;
    flex-direction: row;
    filter: drop-shadow(2px 4px 6px white);
    gap: 10px;
}
.back-button22 {
  background-color: #005253;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 0rem;
  transition: background-color 0.3s;
}
.dd-2{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.social-heading {
  font-size: 1.75rem;
  font-weight: 700;
  color: #010101;
  text-transform: uppercase;
  margin: 0;
  font-family: junge;
}

.social-icons2 {
  display: flex;
  gap: 20px;
}

.social-icon {
  font-size: 1.5rem;
  color: #555;
  transition: color 0.3s, transform 0.3s;
}

.social-icon:hover {
  color: #004e4e;
  transform: scale(1.2);
}

.social-icon:active {
  transform: scale(1.1);
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid black;
}

.social-icon:hover {
  background-color: #e6e6e6;
}

.social-icon:active {
  background-color: #d4d4d4;
}
.footer-widget-content2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 2.5rem;
}
.post-heading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 0rem;
}
.footer-item2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column-reverse;
  gap: 12px;
  width: 50%;
}
.span-div2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.da-text2 {
  font-size: 13px;
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  font-family: sans-serif;
  letter-spacing: 1px;
  width: 25rem;
}
.footer-icon22 {
  width: 10rem;
  height: 7.5rem;
  color: white;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 1.5px white);
  margin-left: 2px;
  border: 1px solid #004e4e;
  filter: drop-shadow(2px 4px 6px black);
}

.arr-icon {
  width: 2rem;
  height: auto;
  padding-bottom: 8px;
  color: #ffffff;
}
.footer-text2 {
  flex: 1 1;
  color: #ffffff;
  font-size: 25px;
  font-family: junge;
}
.blog-detail {
  padding: 2rem;
  /* max-width: 1024px; */
  margin: 0 auto;
  text-align: left;
  padding-top: 115px;
  width: 100%;
}


  
  .back-button2 {
    background-color: #005253;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: background-color 0.3s;
}
  .read-more {
    color: #004e4e; /* Set the text color */
    text-decoration: none; /* Remove underline */
    cursor: pointer;
    font-weight: bold; /* Make it more visible if needed */
  }
  
  .read-more:hover {
    text-decoration: underline; /* Add underline on hover for a link-like appearance */
  }
  
  
  .back-button:hover {
    background-color: #ddd;
  }
  
  .blog-detail h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: 800;
    text-transform: capitalize;
    font-family: junge;
}
.blog-content h1 {
  font-weight: 800;
  text-transform: capitalize;
  font-size: 2.5rem;
  font-family: junge;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
  
  .detail-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1.5rem;
  }
  
  .blog-content {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .blog-toggle button {
      margin: 0.5rem 0.25rem;
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  
    .blog-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  
    .blog-card-content p {
      height: auto;
    }
  }
  



  @media only screen and (max-width: 767px){
    .poster-image {
      width: 100%;
      margin: 0 auto;
      display: block;
      border-radius: 10px;
  }
  .detail-image1 {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1.5rem;
}
.h2-bd {
  font-size: 2rem !important;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.h2-bd{
  font-size: 1.5rem;
}
.blog-detail h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.blog-detail h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.blog-section {
  padding: 1rem;
  text-align: center;
  background-color: #dad9d7;
  padding-top: 7rem;
}
.footer-widget-content2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 2.5rem;
  align-items: flex-start;
}
.post-heading {
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 0rem;
}
.footer-item2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column-reverse;
  gap: 12px;
  width: 40%;
}
.da-text2 {
  font-size: 11px;
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 500;
  text-align: left;
  width: 9rem;
}
.social-heading {
  font-size: 1.2rem;
  font-weight: 700;
  color: #010101;
  text-transform: uppercase;
  margin: 0;
}
.social-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #a7b29f;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  margin-bottom: 45px;
  border: 1px solid #004c4c;
  flex-direction: row;
  filter: drop-shadow(2px 4px 6px white);
  gap: 10px;
  width: 95%;
}
.footer-text2 {
  flex: 1 1;
  color: #ffffff;
  font-size: 16px;
  width: 9rem;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .blog-grid {
      grid-template-columns: repeat(auto-fit, minmax(319px, 1fr));
  }
  .blog-section {
    padding: 2rem;
    text-align: center;
    background-color: #dad9d7;
    padding-top: 10rem;
}
.footer-text2 {
  flex: 1 1;
  color: #ffffff;
  font-size: 21px;
}
.da-text2 {
  font-size: 13px;
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  font-family: sans-serif;
  letter-spacing: 1px;
  width: 18rem;
}
.blog-detail {
  padding: 2rem;
  /* max-width: 1024px; */
  margin: 0 auto;
  text-align: left;
  padding-top: 148px;
  width: 100%;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .footer-widget-content2 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-bottom: 2.5rem;
      align-items: flex-start;
      align-content: flex-start;
  }
  }
 