/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background-color: #edededc2; /* Start with transparent background */
  position: fixed; /* Make it sticky */
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  z-index: 1000; /* Ensure it stays on top */
  height: 73px;
}
.mobile{
  display: none;
}
.navbar.scrolled {
  background-color: #ededed; 
}




.logo {
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.logo-h {
  width: 13rem;
  height: auto;
  filter: drop-shadow(0px 0px 0px black);
}

.mobile-links {
  display: none; 
}


.nav-links {
  display: flex;
  align-items: center;
}


.nav-links a {
  color: #005050;
  text-decoration: none;
  padding: 0 4px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  position: relative;
  font-family: junge;
}

.nav-links a:hover {
  color: #000000;
}


.nav-links a:not(:last-child)::after {
  content: '|';
  color: #005050;
  margin-left: 15px;
}


.nav-links .nav-item a::after {
  content: '|';
  color: #005050;
  margin-left: 15px;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}


.nav-item.open a::after {
  opacity: 0;
}

/* Dropdown styling */
.nav-item {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 1000;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}

.nav-item.open .dropdown {
  transform: scaleY(1);
}

.dropdown-content {
  display: flex;
  width: 25rem;
}

.dropdown-section {
  flex: 1;
  padding: 10px;
  border-right: 1px solid #ddd;
}

.dropdown-section:last-child {
  border-right: none;
}

.dropdown-section h4 {
  margin-bottom: 10px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
}

.dropdown-links a {
  display: block;
  padding: 8px 12px;
  color: #333;
  text-decoration: none;
  font-size: 9px;
}

.dropdown-links a:hover {
  background-color: #f8b400;
  color: white;
}

/* Hamburger menu styling */
.hamburger {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease-in-out;
}

/* Media queries for responsive design */
@media only screen and (max-width: 767px) {
  /* Mobile view: hide nav links and show hamburger */



  .navbar {
    height: 80px;
}
  .nav-links {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: white;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border-top: 2px solid #ddd;
    z-index: 999;
  }
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
  

  .nav-links.open {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    top: 100%;
    justify-content: center;
}
.nav-links a:not(:last-child)::after {
  content: '|';
  color: #005050;
  display: none;
  margin-left: 5px;
}
.nav-links a {
  color: #005050;
  text-decoration: none;
  padding: 0 2px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 19px;
  position: relative;
}

  .hamburger {
    display: flex;
  }

  /* Adjust dropdown for mobile */
  .dropdown-content {
    flex-direction: row;
    width: 100%;
    border: 1px solid green;
    border-radius: 9px;
}
  .dropdown {
    position: absolute;
    top: 100%;
    left: -93px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 1000;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
}

  .dropdown-section {
    border-right: none;
    padding: 0;
  }

  .dropdown-section h4 {
    font-size: 12px;
  }

  .dropdown-links a {
    font-size: 12px;
  }
 
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .nav-links a {
    font-size: 13px;
  }
  .navbar {
    height: 112px;
}

  .dropdown-content {
    width: 20rem;
  }

  .dropdown-section h4 {
    font-size: 12px;
  }
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    background-color: #edededc2;
    flex-direction: column;
}
.nav-links a {
  color: #005050;
  text-decoration: none;
  padding: 0px 4px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  position: relative;
}

  .dropdown-links a {
    font-size: 10px;
  }
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
  
}




