
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Play:wght@400;700&family=Playpen+Sans:wght@100..800&display=swap');
.seller-section {

  width: 100%;
  text-align: center;
  background-color: #ffffff;
}
.seller-section {
  position: relative;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
}

.left-arrow {
  left: 0;  /* Aligns the arrow to the left */
  margin-left: 10px;
}

.right-arrow {
  right: 0; /* Aligns the arrow to the right */
  margin-right: 10px;
}

.slider-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.slider-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.product-card {
  min-width: 300px;
  margin: 0 10px;
  /* Other card styles */
}


.img-petals {
  width: 150px;
  height: auto;
  position: absolute;
  right: 22px;
  transform: rotate(23deg);
  filter: drop-shadow(0px 0px 2px black);
}
.img-petals2 {
  width: 150px;
  height: auto;
  position: absolute;
  left: 22px;
  transform: rotate(334deg);
  filter: drop-shadow(0px 0px 2px black);
}
.description {
  color: #5a5959;
  font-family: sans-serif;
  font-weight: 200;
  font-size: 18px;
  text-align: center;
  height: 11rem;
}
  
.seller-heading {
  font-size: 2.5rem;
  padding: 2rem;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: junge;
  padding-top: 46px;
}
.star-rating {
  color: #ff0000;
  font-size: 26px;
  text-align: center;
}
.star-rating .star {
  font-size: 20px; /* Adjust size if needed */
}
  /* Scrollbar styles */
.slider-container::-webkit-scrollbar {
    height: 10px; /* Adjust the height of the scrollbar */
  }
  
  .slider-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 10px; /* Make the track rounded */
  }
  
  .slider-container::-webkit-scrollbar-thumb {
    background-color: #ff5733; /* Scrollbar thumb color */
    border-radius: 10px; /* Make the scrollbar thumb rounded */
    border: 2px solid #f1f1f1; /* Add a border for better contrast */
    transition: background-color 0.3s ease;
  }
  
  .slider-container::-webkit-scrollbar-thumb:hover {
    background-color: #e14e2b; /* Darker color on hover */
  }
  
  /* For Firefox */
  .slider-container {
    scrollbar-width: thin; /* Make the scrollbar thinner */
    scrollbar-color: #eceae7 #ffffff; /* Custom colors for the scrollbar */
  }
  
 
  .slider-container {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth; /* Smooth scroll */
    padding: 20px 0;
    gap: 0px;
  }
  
  
  .slider-container:active {
    cursor: grabbing;
  }
  
  .product-card {
    min-width: calc(100% / 3 - 79px); /* Show 3 products at a time */
    background-color: #fff;
   
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease;
  }
  .img-s{
    width: 100%;
  height: auto;
  border-radius: 5px;
  overflow: hidden; 
  }
  .product-card img {
    width: 100%;
    height: 17rem;
    border-radius: 8px;
   
    transition: transform 0.5s ease, filter 0.5s ease; 
}
.product-card img:hover{
  
  filter: drop-shadow(4px 8px 12px rgba(0, 0, 0, 0.5));
}
.fl-pp {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  gap: 1rem;
  font-family: sans-serif;
  padding-top: 15px;
  padding-bottom: 15px;
}
  
  .price {
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .view-product12 {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #ff5733;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .view-product12:hover {
    background-color: #e14e2b;
  }
  .product-card {
    border: 0px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    /* border: 1px solid rgb(255, 255, 255); */
    text-align: center;
    background-color: #eceae7;
    transition: transform 0.3s ease;
    display: flex;
  
   
    flex-direction: column;
    justify-content: space-around;
  }
  .flp{
    display: flex;
    justify-content: space-between;
  }
  



  @media only screen and (max-width: 767px){
    .seller-heading {
      font-size: 2rem;
      padding: 1rem;
      margin-bottom: 0px;
      text-transform: uppercase;
      font-weight: 700;
  }
  .slider-container {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
   
}
.fl-pp {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  gap: 1rem;
  font-family: sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
}
.product-card {
  min-width: calc(100% / 1 - 43px);
  background-color: #eceae7;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease;
}
.pricec {
  font-size: 1em;
  font-weight: bold;
  color: #000000;
  /* font-family: "Play", sans-serif; */
}
.description {
  color: #5a5959;
  font-family: sans-serif;
  font-weight: 200;
  font-size: 17px;
  text-align: center;
  height: 11rem;
}
.product-card img {
  width: 100%;
  height: 14rem;
  border-radius: 8px;
  transition: transform 0.5s ease, filter 0.5s ease;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .product-card {
      min-width: calc(100% / 3 - -41px);
      background-color: #eceae7;
      border-radius: 8px;
      padding: 20px;
      text-align: left;
      transition: transform 0.3s ease;
  }
  .description {
    color: #5a5959;
    font-family: sans-serif;
    font-weight: 200;
    font-size: 18px;
    text-align: justify;
    height: 14rem;
}

  }