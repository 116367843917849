.category-section {
  background-color: #8d393c00;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
  padding-top: 0px;
}
.h-c {
  text-align: center;
  font-size: 2rem;
  padding: 1rem;
  margin-bottom: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  /* font-family: 'Arial', sans-serif; */
  font-family: junge;
  padding-top: 3rem;
}
.greenr {
  width: 170px;
  position: absolute;
  height: auto;
  left: 0px;
  opacity: 0.2;
}
.greenl {
  width: 295px;
  position: absolute;
  height: auto;
  opacity: 0.2;
  right: -83px;
  overflow: hidden;
  top: -57px;
  transform: rotate(-27deg);
}
  .category-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .category-section-container {
    /* border: 1px solid #ddd; */
    /* border-radius: 8px; */
    padding: 16px;
    /* background-color: #005050; */
    display: flex;
    justify-content: center;
}
  
.category-cards {
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(119px, 1fr));
  gap: 19px;
  justify-content: center;
  padding: 1rem;
  background-color: #00505000;
  width: fit-content;
  align-content: center;
  z-index: 1;
  border-radius: 10px;
  /* border: 1px solid white; */
}
  
.img-petals22 {
  width: 255px;
  height: auto;
  position: absolute;
  left: 82px;
  transform: rotate(8deg);
  z-index: 0;
  filter: drop-shadow(0px 0px 2px black);
}
.img-petals1 {
  width: 255px;
  height: auto;
  position: absolute;
  right: 86px;
  transform: rotate(10deg);
  filter: drop-shadow(0px 0px 2px black);
  z-index: 0;
}
.category-card {
  position: relative;
  cursor: pointer;
  width: 9rem;
  height: auto;
}
  
.category-image {
  width: 100%;
  height: 6rem;
  border-radius: 55px;
  object-fit: fill;
}
  .category-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    opacity: 0.5;
    transition: opacity 0.3s;
  }
  
  .category-card:hover .category-overlay {
    opacity: 1;
    border: 1px solid white;
  }
  .category-name p {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    text-align: center;
    font-family: sans-serif;
}
  
  .category-overlay p {
    font-size: 0.89em;
    margin: 0;
    font-weight: 700;
    filter: drop-shadow(4px 0px 29px black);
    text-align: center;
}
  

  .toggle-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .toggle-btn {
    background: #d1d0cd;
    border: none;
    border-radius: 5px;
    color: #333;
    cursor: pointer;
    font-family: sans-serif;
    font-size: 1rem;
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    transition: background 0.3s;
  }
  
  .toggle-btn.active {
    background:#a4b09c;
    color: #000000;
  }
  
  .toggle-btn:hover {
    background:#005050;
    color: white;
  }
  
  .category-section-container h3 {
    margin-top: 1rem;
    text-align: center;
  }
  



  @media only screen and (max-width: 767px){
    .category-section {
      padding: 10px;
      background-color: #8d393c00;
  }
  .greenl {
    width: 187px;
    position: absolute;
    height: auto;
    opacity: 0.2;
    right: -83px;
    overflow: hidden;
    top: -57px;
    transform: rotate(-27deg);
}
  .toggle-btn {
    background: #f0f0f0;
    border: none;
    border-radius: 5px;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    transition: background 0.3s;
}
.category-section-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  background-color: #00505000;
}
.category-card {
  position: relative;
  cursor: pointer;
  width: 6rem;
  height: auto;
}
.category-cards {
  display: flex;
  gap: 20px;
  justify-items: center;
  align-content: center;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
}
.category-name p {
  margin-top: 10px;
  font-size: 10px;
  font-weight: bold;
  color: #000000;
  text-align: center;
}
.category-overlay p {
  font-size: 13px;
  margin: 0;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .category-cards {
      display: flex;
      grid-template-columns: repeat(auto-fill, minmax(119px, 1fr));
      gap: 19px;
      justify-content: center;
      padding: 1rem;
      background-color: #00505000;
      width: fit-content;
      align-content: center;
      z-index: 1;
      border-radius: 10px;
      /* border: 1px solid white; */
      flex-wrap: wrap;
  }
  }