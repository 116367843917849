.card-detail-aa {
  width: 100%;
  margin: 2rem auto;
  padding: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding-top: 5rem;
}

.not-found-title-aa {
  color: #e74c3c;
  text-align: center;
}

.back-button-aa, .back-button2-aa {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #fff;
  background-color: #004e4e;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button-aa:hover, .back-button2-aa:hover {
  background-color: #047979;
}

.title-cardd-aa {
  font-size: 3.5rem;
  text-align: left;
  color: #333;
  margin: 0rem 0;
  padding: 1rem 0rem;
}

.disclaimer-headingg-aa {
  font-size: 36px;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
}

.detail-image-aa {
  width: 100%;
  height: 37rem;
  border-radius: 8px;
  margin: 1rem 0;
}

.section-heading-aa {
  font-size: 2.25rem;
  color: #000000;
  font-weight: 800;
  margin: 1.5rem 0 0.5rem 0;
  
}

.description-text-aa, .why-need-text-aa, .products-text-aa, .disclaimer-text-aa {
  font-size: 20px;
  line-height: 1.5;
  color: #3f3f3f;
  margin-bottom: 1rem;
  font-family: sans-serif;
  font-weight: 500;
}

.benefits-heading-aa, .why-need-heading-aa, .products-heading-aa, .disclaimer-headingg-aa {
  font-size: 2.25rem;
  color: #000000;
  font-weight: 800;
  margin: 1.5rem 0 0.5rem 0;
  border-bottom: 1px solid #cfcfcf;
}

.benefits-list-aa {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 1rem;
}

.benefit-item-aa {
  font-size: 20px;
  line-height: 1.5;
  color: #3f3f3f;
  margin-bottom: 1rem;
  font-family: sans-serif;
  font-weight: 500;
}

.card-d-aa {
 
  width: 97%;
}










@media only screen and (max-width: 767px){
  .detail-image-aa {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 1rem 0;
  }
  .card-detail-aa {
    width: 100%;
    margin: 2rem auto;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding-top: 5rem;
}
.title-cardd-aa {
  font-size: 2.5rem;
  text-align: left;
  color: #333;
  margin: 0rem 0;
  padding: 1rem 0rem;
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
  .detail-image-aa {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 1rem 0;
  }
  .card-detail-aa {
    width: 100%;
    margin: 2rem auto;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding-top: 7rem;
}
}
