.modal5 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
}
  
  /* Modal content centered */
  .modal-content5 {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    width: 60%;
    max-width: 800px;
    display: flex;
    position: absolute;
    flex-direction: row;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    animation: slide-down 0.5s ease;
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .close-button5 {
    position: absolute;
    top: -5%;
    font-size: 40px;
    color: #004e4e;
    cursor: pointer;
    transition: color 0.2s ease;
    right: 0px;
}
  
  .close-button5:hover {
    color: #000000;
  }
  
  /* Left side (Image and Overlay) */
  .left-icard {
    position: relative;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .icard-form input::placeholder {
    color: #151515; /* You can change this to your desired color */
    opacity: 1; /* Optional: Ensures full visibility of the placeholder */
    font-size: 17px;
    font-weight: 500;
}
.icard-form textarea::placeholder {
  color: #151515; /* You can change this to your desired color */
  opacity: 1; /* Optional: Ensures full visibility of the placeholder */
  font-size: 17px;
  font-weight: 500;
}
  .icard-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px 0 0 10px;
  }
  .input-time2{
    padding: 10px;
    margin-bottom: 15px;
    border: 2px solid #ddd;
    border-radius: 5px;
    font-family: serif;
    transition: border-color 0.2s ease;
    width: 9.5rem;
  }
 
  .overlay8 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  
  .overlay-title8 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .overlay-text8 {
    font-size: 16px;
  }
  
  .right-icard {
    width: 70%;
    padding: 15px;
}
.fff{
  display: flex;
  flex-direction: column;
}
  
.right-icard h2 {
  margin-bottom: 20px;
  font-size: 20px !important;
  color: #333 !important;
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
.icard-form{
  display: flex;
  flex-direction: column;
}
  
  /* Pair input fields in rows */
  .input-pair {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .input-pair input {
    width: 100%;
  }
  
  .icard-form input {
    padding: 10px;
    margin-bottom: 15px;
    border: 2px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.2s ease;
    width: 9.5rem;
    font-family: serif;
}
.error-message3 {
  color: #e74c3c;
  font-size: 8px;
  margin-top: -0.5rem;
  margin-bottom: 0rem;
  letter-spacing: 1px;
}
.icard-form textarea{
  padding: 10px;
    margin-bottom: 15px;
    border: 2px solid #ddd;
    color: #333;
    border-radius: 5px;
    transition: border-color 0.2s ease;
    width: 100%;
}
  
  .icard-form input:focus,
  .icard-form textarea:focus {
    border-color: #038a8a;
    outline: none;
  }
  
  .icard-form textarea {
    height: 80px;
    resize: none;
    font-family: inherit;
}
  .submit-button {
    width: 35%;
    padding: 8px;
    background: #038a8a;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 23px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 7px;
}
  
  .submit-button:hover {
    background:#06c2c2;
  }
  .icard-container {
    display: flex;
    height: 26rem;
}
  
  /* Responsive design */
  @media (max-width: 768px) {
    .modal-content5 {
      flex-direction: column;
      width: 90%;
    }
  
    .left-icard,
    .right-icard {
      width: 100%;
    }
  
    .left-icard {
      height: 250px;
      display: none;
    }
  }
  




  @media only screen and (max-width: 767px){
    .icard-container {
      display: flex;
      height: auto;
      flex-direction: column;
  }
  .submit-button {
    background-color: #038a8a;
    color: white;
    padding: 3px !important;
    border: none;
    cursor: pointer;
    width: 6rem;
    border-radius: 8px;
    font-size: 12px !important;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
    font-weight: 700;
}
  .right-icard h2 {
    margin-bottom: 14px;
    font-size: 11px !important;
    color: #333 !important;
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
}
.input-time2 {
  padding: 2px;
  margin-bottom: 15px;
  border: 2px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.2s ease;
  width: 5.5rem;
  font-size: 11px;
  font-family: serif;
}

.input-pair {
  display: flex;
  gap: 15px;
  margin-bottom: 0px;
  flex-direction: row;
  flex-wrap: wrap;
}
.icard-form input {
  padding: 2px;
  margin-bottom: 15px;
  border: 2px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.2s ease;
  width: 5.5rem;
  font-size: 12px;
}
.right-icard {
  width: 100%;
  padding: 5px;
}
  }



  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .modal-content5 {
      background: #fff;
      padding: 10px;
      border-radius: 10px;
      width: 75%;
      max-width: 800px;
      display: flex;
      position: absolute;
      flex-direction: row;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      animation: slide-down 0.5s ease;
  }

  }
