.product-cardc {
  border: 0px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  /* border: 1px solid rgb(255, 255, 255); */
  text-align: center;
  background-color: #eceae7;
  transition: transform 0.3s ease;
  display: flex;

  width: 24rem;
  flex-direction: column;
  justify-content: space-around;
}


.view-product12 {
  display: block;
  width: calc(100% - 20px);
  margin: 5px;
  padding: 10px;
  background-color: #070707f8;
  color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.cb2 {
  margin: 5px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  font-family: "Play", sans-serif;
  letter-spacing: 1px;
  text-align: center;
  color: #000000;
}
.cbl2 {
  text-decoration: none;
  color: #ffffff;
}
.view-product12:hover{
  background-color: #005050;
  border: 1px solid white;
  color: #ddd;
}
  .fl-p {
    display: flex;
    justify-content: space-around;
}
 
  
  .product-image {
    width: 100%;
    height: 12rem;
    border-radius: 10px;
    
}
.title-p {
  font-size: 1.4rem;
  margin-top: 1rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-transform:capitalize;
  height: 3.5rem;
  text-align: center;
  line-height: 1.7rem;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  font-family: junge;
}

.descriptionc {
  color: #5a5959;
  font-family: sans-serif;
  font-weight: 200;
  font-size: 18px;
  text-align: center;
  height: 12rem;
}
  
  .price {
    font-size: 1.2em;
    font-weight: bold;
    color: #000000;
    
  }
  .p-size {
    color: #000000 !important;
    margin-bottom: 0;
    font-size: 17px;
}
  .emi{
    color: #fff;
  }
  .star {
    color: #ff0000;
    font-size: 26px;
}
  
  .view-product {
    background-color: #000;
    color: #fff;
    padding: 6px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 11px;
}
  
  .view-product:hover {
    background-color: #333;
  }
  
  .products-section {
    padding: 0px 20px;
    padding-bottom: 20px;
    text-align: center;
   
    background-image: url('../../Assests/pngfl.webp'); /* Background image */
    background-size: cover; /* Cover the entire container */
    background-position: bottom; /* Center the image */ 
    background-color:#d7dad8;
}
  
  .products-section h2 {
    font-size: 2em;
    margin-bottom: 30px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 700;
}
  
.products-grid {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
  
  .see-more-container {
    margin-top: 20px;
  }
  
  .see-more-btn {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .see-more-btn:hover {
    background-color: #333;
  }
  .product-cardc img {
    width: 100%;
    height: 17rem;
    border-radius: 8px;
    
   
}



  @media only screen and (max-width: 767px){
    .products-section {
      padding: 20px 10px;
      text-align: center;
  }
  .products-section h2 {
    font-size: 1.5rem;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: 700;
}
.products-grid {
 
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 0px 0;
  gap: 20px;
  cursor: grab;
}
.descriptionc {
  color: #000000;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 12px;
  height: 7rem;
}
.view-product12 {
  display: block;
  width: calc(100% - 20px);
  margin: 5px;
  padding: 2px;
  background-color: #8d393c00;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 11px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.title-p {
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 700;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  height: 3.5rem;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .product-cardc {
      border: 0px solid #ddd;
      padding: 20px;
      border-radius: 10px;
      /* border: 1px solid rgb(255, 255, 255); */
      text-align: center;
      background-color: #eceae7;
      transition: transform 0.3s ease;
      width: 345px;
  }
  .title-p {
    font-size: 1.4rem;
    margin-top: 1rem;
    font-weight: 700;
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    height: 5.1rem;
}
.descriptionc {
  color: #5a5959;
  font-family: sans-serif;
  font-weight: 200;
  font-size: 16px;
  height: 11rem;
}
  }