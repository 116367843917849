@import url('https://fonts.googleapis.com/css2?family=Satisfy&family=Tangerine:wght@400;700&display=swap');


/* Move.css */
.move-container {
    display: flex;
    flex-direction: row;
    height: 110vh;
    background-color: #a4b09c7a;
    background-image: url('../../Assests/move.png'); /* Replace with the path to your image */
    background-size: cover; /* Ensures the image covers the entire element */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
  }
  
  .left-move {
    height: -webkit-fill-available;
    width: 50%;
}
  .right-move {
    height: -webkit-fill-available;
width: 50%;
  }
  
  .left-move {
    position: relative;
    overflow: hidden;
  }
  
  .image-list {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    animation: move 10s linear infinite;
  }
  .image-list2 {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    right: 0;
    gap: 20px;
    animation: move 15s linear infinite;
}
  
  .top-to-bottom {
    animation-direction: normal;
  }
  
  .bottom-to-top {
    animation: move 10s linear infinite;
    animation-direction: reverse;
  }
  
  .image-card {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  
  .image-card img {
    width: 100%;
    height: 25rem;
    object-fit: cover;
    border-top-left-radius: 8em;
    border-top-right-radius: 8em;
    border-bottom-left-radius: 8em;
    border-bottom-right-radius: 8em;
    filter: drop-shadow(0px 0px 2px black);
}
  
.right-move {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
  padding-right: 69px;
}
.right-move-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 31px;
}
  
.right-move h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  font-weight: 800;
  font-style: normal;
  text-align: right;
  text-transform: capitalize;
  font-family: junge;
}
  
.right-move p {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: right;
  font-weight: 500;
  font-family: junge;
}
.right-move .p22 {
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: right;
    font-weight: 400 !important;
    font-family: Arial, Helvetica, sans-serif !important;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.button-17 {
  align-items: center;
  appearance: none;
  background-color:#004e4e;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.button-17:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}
  
  @keyframes move {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  




  @media only screen and (max-width: 767px){
    .move-container {
      display: flex;
      height: auto;
      background-color: #a4b09c7a;
      background-image: url('../../Assests/move.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      flex-direction: column;
  }
  .right-move-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 0px;
}
.left-move {
  height: 32rem;
  width: 100%;
}
.right-move {
  height: auto;
  width: 100%;
}
  .image-list {
    position: absolute;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    animation: move 10s linear infinite;
    align-items: center;
}
.image-list2 {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  right: 0;
  gap: 20px;
  animation: move 15s linear infinite;
  align-items: center;
}
.right-move {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
  padding-right: 22px;
}
.right-move h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  text-align: center;
  text-transform: capitalize;
}
.right-move p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  text-align: center;
}
.right-move .p22 {
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center !important;
  font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.right-move-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
  }
  
  
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .move-container {
      display: flex;
      height: 600px !important;
      background-color: #a4b09c7a;
      background-image: url('../../Assests/move.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding-top: calc(112px);
  }
 
  .right-move h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: right;
    text-transform: capitalize;
}
.right-move p {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: right;
  font-weight: 500;
  font-family: junge;
}
.right-move .p22 {
  font-size: 1rem;
  margin-bottom: 7px;
  text-align: right;
  font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}
  }
