@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&family=Calligraffitti&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Arizonia&family=Baskervville+SC&family=Bilbo+Swash+Caps&family=Calligraffitti&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arizonia&family=Baskervville+SC&family=Bilbo+Swash+Caps&family=Calligraffitti&family=Crete+Round:ital@0;1&family=Playwrite+AU+TAS:wght@100..400&display=swap');
.App {

  text-align: center;
}
/* Prevent text selection across the whole website */

* {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Standard syntax */
}


html, body {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Cormorant Garamond", serif; /* Ensure the font supports numeric features */
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full page height */
  background-color: #f9f9f9; /* Optional background */
}

.moving-image {
  width: 100px; /* Adjust size as needed */
  animation: move 2s infinite ease-in-out;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Specific font feature settings for numbers */
body {
  font-feature-settings: "lnum" 1, "onum" 0; /* Lining numerals (lnum) enabled, old-style numerals (onum) disabled */
  font-variant-numeric: tabular-nums; /* Ensures numbers have uniform width */
}

/* Fallback option if the font doesn't support the above features */
html, body {
  font-family: "Cormorant Garamond", "Roboto", serif; /* Add Roboto as a fallback font */
}



@media only screen and (max-width: 767px){
  body {
    font-feature-settings: "lnum" 1, "onum" 0; /* Lining numerals (lnum) enabled, old-style numerals (onum) disabled */
    font-variant-numeric: tabular-nums; /* Ensures numbers have uniform width */
  }
  html, body {
   
    box-sizing: border-box;
    font-family: "Cormorant Garamond", serif; /* Ensure the font supports numeric features */
    font-optical-sizing: auto;
   
    font-style: normal;
  }
  
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  body {
    font-feature-settings: "lnum" 1, "onum" 0; /* Lining numerals (lnum) enabled, old-style numerals (onum) disabled */
    font-variant-numeric: tabular-nums; /* Ensures numbers have uniform width */
  }
  html, body {
   
    box-sizing: border-box;
    font-family: "Cormorant Garamond", serif; /* Ensure the font supports numeric features */
    font-optical-sizing: auto;
   
    font-style: normal;
  }
  
  
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body, html {
  scrollbar-width: thin; /* For Firefox */
  
}

body::-webkit-scrollbar, html::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  height: 8px; /* Height of the scrollbar */
}

body::-webkit-scrollbar-track, html::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

body::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

body::-webkit-scrollbar-thumb:hover, html::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
