.imgSliderWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 1rem;
    justify-content: center;
}
.sw{
  color: rgb(16, 133, 3);
  background-color: aliceblue;
  border-radius: 50%;
}
.bf{
 width: 20rem;
    height: 13rem;
}
.ba{
 width: 20rem;
  height: 13rem;
}
.EmbedBrokenMedia {
  padding-bottom: 40%;
  padding-top: 7%;
}
.banda {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #333;
  text-transform: uppercase;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 4rem;
  font-family: junge;
}
  
  .imgSliderContainer {
    position: relative;
    width: 293px;
    height: 250px;
    overflow: hidden;
}
  
  .img-wrapper {
    position: relative;
    width: 100%;
    height: 100%; /* Full height of the container */
  }
  
  .img-afterWrap,
  .img-beforeWrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; /* Stretch images to fill the container */
  }
  
  .img-beforeWrap {
    overflow: hidden; /* Hide overflow of the before image */
    transition: width 0.2s ease; /* Smooth transition for dragging effect */
  }
  
  .sliderIndicator {
    position: absolute;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position */
    z-index: 10; /* Make sure it’s on top of the images */
  }
  







  @media only screen and (max-width: 767px){
    .banda {
      text-align: center;
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 0px;
      color: #333;
      text-transform: uppercase;
      padding: 1rem;
      padding-top: 1rem;
      padding-bottom: 2rem;
  }
  .imgSliderWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    padding-bottom: 1rem;
    justify-content: center;
}
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px){
  
  }
