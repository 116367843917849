.divider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  
  .small-star {
    font-size: 25px;
    opacity: 0.5;
    color: #6b5c4c;
    margin: 0 10px;
    transition: transform 0.3s ease;
  }
  
  .big-star {
    font-size: 40px;
    opacity: 1;
    color: #6b5c4c;
    margin: 0 10px;
    transition: transform 0.3s ease;
  }
  
  .divider .small-star:hover,
  .divider .big-star:hover {
    transform: scale(1.2);
  }
  