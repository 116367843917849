/* Mimg.css */

/* Container for the image slider */
.mimg-container {
  width: 90%;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 0px 0;
  padding-bottom: 30px;
}

.mimg-head {
  text-align: center;
  padding: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  color: #333;
  font-family: junge;
}

/* Individual slide padding */
.image-slide {
  padding: 10px;
  position: relative; /* Ensure the overlay is properly positioned */
  border-radius: 10px;
  overflow: hidden; /* Ensures overlay doesn't overflow */
}

/* Slick slider track alignment */
.slick-track {
  display: flex;
  align-items: center;
}

/* Link wrapping the image */
.image-link {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  text-decoration: none; /* Remove underline from links */
  cursor: pointer; /* Indicate clickable area */
}

/* Larger image for even-indexed slides */
.large-slide .slider-image {
  height: 400px; /* Larger image */
}

/* Default image styling */
.slider-image {
  width: 100%;
  height: 250px; /* Default image size */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 10px;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

/* Image hover effect */
.slider-image:hover {
  transform: scale(1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Hide slick slider arrows */
.slick-prev, .slick-next {
  display: none !important;
}

/* Slick slider dots */
.slick-dots {
  bottom: -30px;
  display: none !important;
}

/* Overlay that appears on image hover */
.image-overlay {
  position: relative;
}

.overlay-m {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); /* Transparent initially */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: background 0.5s ease, transform 0.5s ease;
  transform: scale(0); /* Bubble effect */
}

.image-slide:hover .overlay-m {
  background: rgba(0, 0, 0, 0.5); /* Dark effect on hover */
  transform: scale(1); /* Animate to full size */
}

/* Instagram Button */
.instagram-btn {
  border: 5px solid black;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  color: red;
  background-color: rgba(0, 0, 0, 0.8);
  transition: background-color 0.3s ease, border 0.3s ease;
}

.instagram-btn:hover {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fc005f;
}

.btn-ic2 {
  font-size: 30px;
  color:#fc005f;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .play-btn, .instagram-btn {
    padding: 12px;
  }
  .large-slide .slider-image {
    height: 510px;
}
  .btn-ic {
    font-size: 20px;
  }

  .instagram-button-container {
    top: 65%; /* Adjust positioning for smaller screens */
  }
}

@media (max-width: 480px) {
  .play-btn, .instagram-btn {
    padding: 10px;
  }

  .btn-ic {
    font-size: 18px;
  }

  .instagram-button-container {
    top: 110%; /* Further adjust positioning for very small screens */
  }
}

/* "Shop Now" button styling */
.shop-now-btn {
  background-color: #ff5a6000;
  color: white;
  padding: 10px 20px;
  border: 1px solid rgb(0, 0, 0);
  font-size: 16px;
  border-radius: 20px;
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: relative;
  top: 0%;
  left: 0%;
}
.shop-now-btn2 {
  background-color: #ff5a6000;
  color: white;
  padding: 10px 20px;
  border: 1px solid rgb(0, 0, 0);
  font-size: 16px;
  border-radius: 20px;
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: relative;
  top: 7px;
  left: 37%;
}

.image-slide:hover .shop-now-btn {
  opacity: 1;
  transform: scale(1.05);
}
.image-slide:hover .shop-now-btn2 {
  opacity: 1;
  transform: scale(1.05);
}

/* Scrollbar styling */
.m-content::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 4px;
}

.m-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

.m-content::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 3px; /* Rounded corners for the thumb */
}

.m-content::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

/* Modal Overlay */
.m-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease; /* Optional fade-in animation */
}

/* Modal Content */
.m-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto; /* Enable scrolling if content overflows */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.3s ease; /* Optional slide-in animation */
}

/* Close Button */
.m-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
  transition: color 0.2s ease;
}

.m-close-button:hover {
  color: #ff5a5f; /* Change color on hover */
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* Media Queries for slick slider adjustments */
@media only screen and (max-width: 767px){
  .slick-slider .slick-track, .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }  
  .slider-image {
    width: 100%;
    height: 320px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}
}


@media only screen and (min-width: 768px) and (max-width: 1023px){

}