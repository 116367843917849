.image-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  .loading-animation {
    width: 200px; 
    
  }
  
 
  
  .hidden {
    visibility: hidden;
  }
  
  .visible {
    visibility: visible;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }
  