.benefits-section {
    text-align: center;
    padding: 60px 20px;
    background-color: #dad9d7;
  }
  
  .benefits-heading {
    font-size: 2.5rem;
    margin-bottom: 50px;
    color: #000000;
   font-family: Junge;
    text-transform: uppercase;
    font-weight: 700;
}
  
  .benefits-cards {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .benefit-card {
    background: white;
    border-radius: 10px;
    padding: 20px;
    width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #0e5655;
}
  
  .benefit-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px #0e5655;
  }
  
  .benefit-icon {
    font-size: 3rem;
    color: #0e5655;
    margin-bottom: 20px;
  }
  
  .benefit-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 700;
    font-family: Junge;
    letter-spacing: 1px;
}
  
.benefit-description {
  font-size: 1rem;
  color: #0c0c0c;
  text-transform: none;
  letter-spacing: 1px;
  font-family: sans-serif;
  font-weight: 500;
}
  
  @media (max-width: 768px) {
    .benefit-card {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  



  @media only screen and (max-width: 767px){
    .benefits-section {
      text-align: center;
      padding: 20px 10px;
      background-color: #dad9d7;
  }
  .benefits-heading {
    font-size: 1.5rem;
    margin-bottom: 40px;
    color: #000000;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
}
.benefits-cards {
  display: flex;
  justify-content: space-around;
  gap: 0px;
  flex-wrap: wrap;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .benefit-card {
      width: 43%;
      margin-bottom: 20px;
  }
  }