@import url('https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&display=swap');

.footer-widget-content {
  display: flex;
  flex-direction: column;
}

.footer-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row-reverse;
  gap: 12px;
  justify-content: flex-end;
}
.span-div{
  display: flex;
  flex-direction: column;
}
.da-text {
  font-size: 12px;
  color: #393939;
  margin-bottom: 0;
  font-weight: 500;
  font-family: sans-serif;
}

input[type="text"]::placeholder {
  color: #ffffff; /* Change this to your desired color */
}
.button-4 {
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 9px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.button-4:hover {
  background-color: #F3F4F6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.button-4:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.button-4:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.button-4:focus {
  outline: 1px transparent;
}

.button-4:before {
  display: none;
}

.button-4:-webkit-details-marker {
  display: none;
}
.e-head {
  font-weight: 800;
  font-family: 'Cormorant Garamond';
  letter-spacing: 1px;
  color: white;
  filter: drop-shadow(2px 4px 6px black);
}
.e-head-span {
  color: #ffffff;
  filter: drop-shadow(0px 0px 0px white);
  letter-spacing: 0px;
}
.add{
  font-size: 1.2rem;
  color: #505050;
}
.icon-cm{
  width: 3rem;
  height: auto;
  color: #004e4e;
}

.footer-text {
  flex: 1 1;
  color: #161616;
  font-size: 19px;
}

.footer-icon {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  margin-left: 10px;
}



.footer-section {
    background: #ededed;
    position: relative;
  }
  .containerr{
    width: 100% !important;
  }
  @media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: fit-content;
    }
}
.footer-icon {
  width: 2rem;
  height: 2.3rem;
  color: #0c0c0c;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 1.5px white);
  margin-left: 2px;
}
.footer-icon1 {
  width: 1rem;
  height: auto;
  color: white;
  margin-top: 1px;
}
.b-h {
  color: #3c3c3c;
  font-size: 21px;
  font-weight: 800;
  position: relative;
  text-transform: uppercase;
  margin-top: 25px;
  font-family: junge;
}
.ftt {
  font-size: 1.4rem !important;
  margin-bottom: 0;
  font-weight: 600;
}
.b-h-s {
  color: rgb(65, 63, 63);
  font-size: 16px;
  font-weight: 500;
  font-family: sans-serif;
}
.footer-widget-heading .h3-h {
  color: #0d0d0d;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 40px;
  position: relative;
  text-transform: uppercase;
  padding-left: 38px;
}
.footer-widget-heading .h3-h::before {
  content: "";
  position: absolute;
  left: 40px;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #004e4e;
}
  ul{
    padding-left: 0;
    text-decoration: none;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-right: calc(var(--bs-gutter-x)* -.5);
    margin-left: calc(var(--bs-gutter-x)* -.5);
}
.footer-cta {
  border-bottom: 1px solid #373636;
  padding-bottom: 25px !important;
  background-color: #004e4e;
  border-radius: 10px;
}
  .single-cta i {
    color: #004e4e;
    font-size: 30px;
    float: left;
    margin-top: 8px;
  }
  .single-cta {
    display: flex;
    justify-content: center;
    align-items: center;
}
.placehol{
  display: flex;
  align-content: center;
  align-items: center;
  gap: 6px;
}

.sn{
  width: 100%;
  padding: 12px;
  margin-bottom: 0rem !important;
  border: 2px solid #005050;
  border-radius: 8px;
  font-size: 1rem;
  background-color: #c1ccb9;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.row1 {
  display: flex;
  justify-content: center;
  gap: 0rem;
  align-items: center;
  flex-direction: column;
}
.row1 p {
  color: white;
  font-size: 18px;
}
  .cta-text {
    padding-left: 15px;
    display: inline-block;
  }
  .cta-text h4 {
    color: #1b1b1b;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 2px;
    text-transform: uppercase;
}
  .cta-text span {
    color: #262525;
    font-size: 15px;
  }
  .footer-content {
    position: relative;
    z-index: 2;
  }
  .footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
  }
  .footer-logo img {
    max-width: 230px;
    filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
    margin-top: 0px;
}
.footer-text p {
  margin-top: 14px;
  font-size: 20px;
  color: #161616;
  line-height: 25px;
}
  .footer-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.footer-icon2 {
  width: 3rem;
  height: 2.5rem;
  color: white;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 1.5px white);
  margin-left: 2px;
}
  .footer-social-icon a {
    color: #171717;
    font-size: 20px;
    margin-right: 0px;
  }
  .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
  }
  .facebook-bg {
    background: #3b5998;
  }
  .twitter-bg {
    background: #55acee;
  }
  .google-bg {
    background: #dd4b39;
  }
  .footer-widget-heading h3 {
    color: #0d0d0d;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 40px;
    position: relative;
    text-transform: uppercase;
    font-family: junge;
}
  .footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: #004e4e;
  }
.footer-widget ul li {
    display: inline-block;
    width: 80%;
    margin-bottom: 7px;
    padding-left: 40px;
}
.t-cm {
  display: flex;
  gap: 40px;
}
.t-cm-p {
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  color: #0d0d0d;
  display: flex;
  font-size: 20px;
  flex-direction: column-reverse;
  align-items: center;
}
.t-cm-l {
  text-decoration: none;
  color: #202020;
  font-size: 20px !important;
}
.footer-textt {
  flex: 1 1;
  color: #161616;
  font-size: 19px;
  width: 15rem;
}
.tlogo {
  width: 115px;
  height: auto;
  filter: drop-shadow(0px 0px 0px black);
}
  .footer-widget ul li a:hover {
    color: #004e4e;
  }
  .footer-widget ul li a {
    color: #161616;
    text-decoration: none;
    text-transform: capitalize;
    font-size: 19px;
}
  .subscribe-form {
    position: relative;
    overflow: hidden;
  }
  .subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #2e2e2e;
    border: 1px solid #2e2e2e;
    color: #fff;
  }
  .subscribe-form button {
    position: absolute;
    right: 0;
    background: #a94448;
    padding: 1px 8px;
    border: 1px solid #ff5e14;
    top: 0;
}
.error-text{
  color: rgb(255, 255, 255);
  font-size: 15px !important;
  margin-bottom: 0;
}
  .subscribe-form button i {
    color: #fff;
    font-size: 22px;
    transform: rotate(-6deg);
  }
  .copyright-area {
    background: #ffffff;
    padding: 2px 0;
  }
  .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #2a2a2a;
    font-family: sans-serif;
    font-weight: 500;
}
  .copyright-text p a {
    color: #a94448;
  }
  .footer-menu li {
    display: inline-block;
    margin-left: 20px;
  }
  .footer-menu li:hover a {
    color: #a94448;
  }
  .footer-menu li a {
    font-size: 14px;
    color: #878787;
  }
  .footer-cta .row .col-xl-4:nth-child(1) .single-cta i {
    position: absolute;
    color: #a94448;
  }
  .footer-cta .row .col-xl-4:nth-child(1) .single-cta .cta-text {
    padding-left: 17px;
  }
  .footer-cta .row .col-xl-4:nth-child(2) .single-cta i {
    color: #a94448;
  }
  .footer-cta .row .col-xl-4:nth-child(3) .single-cta i {
    color: #ffd700;
  }
  .footer-social-icon .social_icon {
    text-align: left;
  }
  .footer-social-icon .social_icon li {
    
    display: inline-block !important;
    width: inherit;
  }
  .footer-social-icon .social_icon li a i {
    font-size: 15px;
    line-height: 39px;
  }
  .footer-social-icon .social_icon li a {
    width: 30px;
    height: 30px;
}
  .ul-link {
    padding-left: 0;
    text-decoration: none;
    display: flex;
    flex-direction: column;
}
.com-t {
  color: #161616 !important;
  margin-top: 7px;
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}
.sn{
  padding: 6px !important;
}
.liii{
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
}
.lll{
  text-decoration: none;
}
.liii:hover{
  color: rgb(3, 89, 13) !important;
}

.copyright-text{
  padding: 10px;
}
.footer-widget{
  padding-left: 0px;
}
  
  .social_icon {
    text-align: right;
    margin: 0;
    margin-top: 3px;
  }
  .social_icon li {
    display: inline-block;
    margin: 0 5px;
  }
  .social_icon li a {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: #fff;
    color: #000;
    text-align: center;
    border-radius: 100%;
    transition: 0.2s ease-in-out;
  }
  .social_icon li a i {
    font-size: 15px;
    line-height: 20px;
  }
  .social_icon li:nth-child(1) a {
    background: #4267b2;
    color: #fff;
  }
  .social_icon li:nth-child(2) a {
    background: #1da1f2;
    color: #fff;
  }
  .social_icon li:nth-child(3) a {
    background: #f09433;
    background: -moz-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: -webkit-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    color: #fff;
  }
  .social_icon li:nth-child(4) a {
    background: #ff0000;
    color: #fff;
  }
  .social_icon li:nth-child(1):hover a {
    background: #c77f7f;
    color: #4267b2;
  }
  .social_icon li:nth-child(2):hover a {
    background: #db9999;
    color: #1da1f2;
  }
  .social_icon li:nth-child(3):hover a {
    background: #d88e8e;
    color: #bc1888;
  }
  .social_icon li:nth-child(4):hover a {
    background: #da9393;
    color: #ff0000;
  }
  



  @media only screen and (max-width: 767px){
    .footer-cta .row .col-xl-4:nth-child(1) .single-cta .cta-text {
      padding-left: 15px;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -.5);
    margin-left: calc(var(--bs-gutter-x)* -.5);
    gap: 16px;
}
.footer-logo img {
  max-width: 160px;
  filter: drop-shadow(0px 0px 0px white);
}
.footer-widget {
  padding-left: 5px;
  text-align: center;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #a94448;
  padding: 0px 9px;
  border: 1px solid #ff5e14;
  top: 1px;
}
.e-head {
  
  font-weight: 700;
  font-family: "Play", sans-serif;
  letter-spacing: 1px;
  color: white;
  filter: drop-shadow(2px 4px 6px black);
  font-size: 1rem;
  margin-bottom: 0;
}
.row1 p {
  color: white;
  font-size: 12px;
  padding: 10px;
  margin-bottom: 0;
  text-align: center;
}
.row1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 11px;
}
.footer-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 3px;
  justify-content: flex-end;
}
.footer-widget ul li {
  display: inline-block;
  width: fit-content;
  margin-bottom: 15px;
  padding-left: 0px;
}
.ul-link {
  padding-left: 0;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.ul-link {
  padding-left: 0;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.footer-widget-heading .h3-h {
  color: #0d0d0d;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
  position: relative;
  text-transform: uppercase;
  padding-left: 0px;
}
.footer-widget-heading .h3-h::before {
  content: "";
  position: absolute;
  left: 42%;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #004e4e;
}
.t-cm {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 42%;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #004e4e;
}
.footer-widget-heading h3 {
  color: #0d0d0d;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
  position: relative;
  text-transform: uppercase;
  font-family: junge;
  text-align: center;
}
.footer-textt {
  flex: 1 1;
  color: #161616;
  font-size: 19px;
  width: 15rem;
  text-align: left;
}
.da-text {
  font-size: 12px;
  color: #393939;
  margin-bottom: 0;
  font-weight: 500;
  font-family: sans-serif;
  text-align: left;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .footer-widget {
      padding-left: 0px;
  }
  .subscribe-form button {
    position: absolute;
    right: 0;
    background: #a94448;
    padding: 8px 20px;
    border: 1px solid #ff5e14;
    top: 0;
}
.footer-logo img {
  max-width: 181px;
  filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
  margin-top: 0px;
}
.footer-textt {
  flex: 1 1;
  color: #161616;
  font-size: 19px;
  width: 12rem;
}
.footer-widget ul li {
  display: inline-block;
  width: 90%;
  margin-bottom: 7px;
  padding-left: 35px;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  padding-left: 1px;
  padding-right: 1px;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-right: calc(var(--bs-gutter-x)* -.5);
  margin-left: calc(var(--bs-gutter-x)* -.5);
  justify-content: center;
}
.t-cm {
  display: flex;
  gap: 40px;
  justify-content: flex-start;
}
.footer-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: row-reverse;
  gap: 12px;
  justify-content: flex-end;
}
  }




  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .col-md-6 {
      flex: 0 0 auto;
      width: 33%;
  }
  .footer-widget ul li {
    display: inline-block;
    width: 100%;
    margin-bottom: 7px;
    padding-left: 35px;
}
  }
