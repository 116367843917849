@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Pacifico&display=swap');

.thumbnails {
  display: flex;
  margin-top: 13px;
  flex-wrap: wrap;
  gap: 5px;
  width: 5rem;
  justify-content: flex-start;
}

.thumbnail-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: border 0.3s, transform 0.3s;
}

.thumbnail-image:hover,
.thumbnail-image.active {
  border: 2px solid #004e4e; /* Highlight color */
  transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .thumbnails {
    gap: 8px;
  }

  .thumbnail-image {
    width: 50px;
    height: 50px;
  }
}

.shop-details-container {
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 5rem;
}

.back-buttona {
  background-color:#004e4e;
  border: none;
  color: #ffffff;
  cursor: pointer;
  top: -52px;
  left: 46px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  transition: color 0.3s; /* Smooth color transition */
}
.back-button {
  background-color:#004e4e;
  border: none;
  color: #ffffff;
  cursor: pointer;
  top: -52px;
  left: 46px;
  margin-bottom: 20px;
  font-size: 16px;
  transition: color 0.3s; /* Smooth color transition */
}

.back-button:hover {
  color: #070707; /* Darker shade on hover */
  color: white;
}

.product-details {
  display: flex;
  gap: 20px;
  height: auto;
  position: relative;
  margin-bottom: 30px;
}

.left-div {
  /* flex: 1 1; */
  position: relative;
  padding: 10px;
  background-image: url('../../Assests/bci2.jpg');
  background-position: center;
  background-repeat:no-repeat;
  background-size: auto;
  border-radius: 10px;
  width: 55%;
}
.kkk {
  position: sticky;
  top: 22%;
  display: flex;
  padding-bottom: 2rem;
  flex-direction: row-reverse;
  align-items: center;
}
.cccc{
  display: flex;
  width: 100%;
}

.right-div {
  flex: 1 1; /* Allow the right div to take available space */
  display: flex; /* Allow for flex layout */
 
  flex-direction: column; /* Stack child elements vertically */

 
}
.right-div::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.product-image {
  width: 100%;
  height: 34rem;
  max-height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  object-fit: fill;
  /* position: sticky !important; */
  bottom: 0;
}

.product-title {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  font-family: junge;
}
.siz {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.product-subtitle {
  font-size: 22px;
  margin-top: 10px;
  color: #555; /* Lighter shade for subtitle */
}


.product-description {
  margin-top: 5px;
  font-size: 18px;
  margin-bottom: 6px;
  color: #666;
  line-height: 1.3;
}
.section-title {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 2rem;
  font-family: junge;
  margin-bottom: 2rem;
}
.how-description {
  font-size: 27px;
  color: #656666;
}
.direction-description{
  font-size: 27px;
  color: #656666;
}
.kyin {
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-bottom: 5rem;
  font-weight: 700;
  margin-top: 4rem;
  text-align: left;
  font-family: junge;
}
.fullfaq {
  display: flex;
  padding: 1rem;
  margin-bottom: 0rem;
  justify-content: space-between;
  gap: 4rem;
  position: relative;
  margin-top: 3rem;
}
.ingredients-sectionn {
  padding-left: 0px;
  text-align: center;
  background-color: #f8f9fa;
  padding-right: 0px;
  padding-bottom: 31px;
}
.back-buttonaa {
  background-color: #004e4e;
  border: none;
  color: #ffffff;
  cursor: pointer;
 
  width: 50%;
 
  padding: 10px 20px;
  border-radius: 10px;
 
  font-size: 16px;
  transition: color 0.3s;
}
.butt-div {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}
.product-price-d {
  font-size: 20px;
  font-weight: bold;
  margin-top: 7px;
  color: #000000;
  margin-bottom: 5px;
  width: 100%;
}

.consult-button {
  margin-top: 8px;
  padding: 10px 24px;
  background-color: #004e4e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
  margin-bottom: 10px;
  width: 100%;
}

.consult-button:hover {
  background-color: #016363; /* Darker shade on hover */
}

.collapse-section {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 5px;
  transition: box-shadow 0.3s;
}

.collapse-header {
  display: flex;
  justify-content: space-between;
  padding: 7px;
  cursor: pointer;
  background-color: #014b4b22;
  border-radius: 5px;
  transition: background-color 0.3s;
  align-items: center;
}
.hjj {
  font-size: 15px;
  text-transform: uppercase;
  font-family: junge;
  letter-spacing: 1px;
}
.collapse-header:hover {
  background-color: #e9ecef; /* Darker shade on hover */
}

.collapse-content {
  padding: 15px;
  background-color: #fff;
}

.ingredients-sectionn {
  margin-top: 40px;
}

.ingredients-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.ingredient-item {
  width: 335px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s;
}

.ingredient-item:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.ingredient-image {
  width: 100%;
  height: 12rem;
  border-radius: 5px;
}

.overlayy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for better focus */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure overlay is on top */
}

.overlay-contenty {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for overlay */
}

.overlay-imagey {
  width: 445px;
  height: auto;
  margin-bottom: 10px;
  border-radius: 5px; /* Rounded corners for overlay image */
}
.overlay-namey {
  margin-bottom: 0;
  font-weight: 700;
  text-transform: uppercase;
}
.ingredient-name{
 
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
}
.product-subtitle {
  font-size: 19px;
  margin: 5px 0;
  font-family: sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #004e4e;
}
.close-buttony {
  position: absolute;
  top: -3px;
  right: -7px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.faq-section {
  margin-top: 30px;
}

.faq-title {
  font-size: 25px;
  margin-bottom: 10px;
  color: #333;
  font-weight: 700;
  text-transform: uppercase;
  font-family: junge;
}
.incre-str{
  font-weight: bold;
  font-size: 18px;
  color: #045252;
}
.ing{
  font-weight: bold;
  font-size: 18px;
  color: #045252;
}
.strong-how {
  font-size: 30px;
  color: #000000;
}
.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 20px;
}

.question:hover {
  color: #004e4e; /* Color change on hover */
}

.arrow-icon {
  margin-left: 10px;
}

.answer {
  margin-left: 0px;
  color: #004e4e;
  margin-top: 5px;
  font-family: sans-serif;
  font-weight: 600;
}

.consult-section {
  margin-top: 30px;
  text-align: center;
}

.consult-title {
  font-size: 25px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
  width: 33rem;
  font-family: junge;
}

.consult-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.whatsapp-button,
.consult-book-button {
  padding: 12px 24px; /* Increased padding */
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s; /* Smooth background transition */
}

.whatsapp-button {
  background-color: #0e0e0e;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}

.whatsapp-button:hover {
  background-color:#004e4e; /* Darker shade on hover */
 
  color: rgb(255, 255, 255);
}

.consult-book-button {
  background-color: #000000;
  color: white;
}

.consult-book-button:hover {
  background-color: #004e4e; /* Darker shade on hover */
  
  color: rgb(255, 255, 255);
}
.highlight-text{
  font-weight: 600;
  color: rgb(38, 38, 38);
  font-family: sans-serif;
  font-size: 15px;
}





@media screen and (max-width: 768px){
  .product-details {
    display: flex;
    gap: 20px;
    height: auto;
    position: relative;
    margin-bottom: 30px;
    flex-direction: column;
}
.strong-how {
  font-size: 20px;
  color: black;
}
.left-div {
  /* flex: 1 1; */
  position: relative;
  padding: 10px;
  width: 100%;
}
.consult-title {
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 700;
  width: 17rem;
}
.section-title {
  font-size: 2rem;
  text-transform: uppercase;
}
.how-description {
  font-size: 16px;
}
.kyin {
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.kkk {
  position: sticky;
  top: 22%;
  display: flex;
  padding-bottom: 0rem;
  flex-direction: column;
  align-items: center;
}
.thumbnails {
  display: flex;
  margin-top: 13px;
  gap: 5px;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.product-image {
  width: 100%;
  height: auto;
  max-height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  object-fit: fill;
  /* position: sticky !important; */
  bottom: 0;
}
.how-description {
  font-size: 20px;
  margin-bottom: 0;
}
.direction-description {
  font-size: 20px;
  color: #656666;
}
.section-title {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 0rem;
}
.overlay-imagey {
  width: 276px;
  height: auto;
  margin-bottom: 10px;
  border-radius: 5px;
}
.ingredients-section {
  padding-left: 50px;
  text-align: center;
  background-color: #f8f9fa;
  padding-right: 50px;
  padding-bottom: 20px;
}
.fullfaq {
  display: flex;
  padding: 1rem;
  margin-bottom: 0rem;
  justify-content: space-between;
  gap: 0rem;
  position: relative;
  flex-direction: column;
  margin-top: 0;
}

}

@media only screen and (min-width: 768px) and (max-width: 1024px){
  .overlay-imagey {
    width: 386px;
    height: auto;
    margin-bottom: 10px;
    border-radius: 5px;
}
.left-div {
  /* flex: 1 1; */
  position: relative;
  padding: 10px;
  width: 100%;
}
.shop-details-container {
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 7rem;
}


.product-details {
  display: flex;
  gap: 20px;
  height: auto;
  position: relative;
  margin-bottom: 30px;
  flex-direction: column;
}
.kkk {
  position: sticky;
  top: 22%;
  display: flex;
  padding-bottom: 0rem;
  flex-direction: column;
  align-items: center;
}
.thumbnails {
  display: flex;
  margin-top: 13px;
  gap: 5px;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.product-image {
  width: 100%;
  height: auto;
  max-height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  object-fit: fill;
  /* position: sticky !important; */
  bottom: 0;
}

}



@media only screen and (min-width: 768px) and (max-width: 1024px){
  .fullfaq {
    display: flex;
    padding: 1rem;
    margin-bottom: 0rem;
    justify-content: space-between;
    gap: 0rem;
    position: relative;
    flex-direction: row;
    margin-top: 0;
    align-items: flex-start;
  }
  .faq-section {
    margin-top: 30px;
    width: 50%;
}
}
