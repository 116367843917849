.raw {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
}
.raw h2 {
  margin-bottom: 15px;
  font-size: 2rem;
  font-weight: bold;
  padding: 2rem;
  text-transform: uppercase;
  font-family: junge;
}
.raw-t {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
.raw-t2 {
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
.raw-t22 {
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
.raw-p {
  font-size: 16px;
  width: 23rem;
  letter-spacing: 1px;
  font-family: sans-serif;
  font-weight: 500;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
}

.card {
  width: 395px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-weight: bold;
  background-color: white;
  position: relative; /* Ensure position is set for proper child alignment */
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the back side when not visible */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  transition: transform 0.6s;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.card-back {
  background-color: rgb(4 130 130);
  color: rgb(255, 255, 255); /* Change text color for readability */
  transform: rotateY(180deg); /* Rotate back side 180 degrees */
}

/* Flip the card on hover for all but the last card */
.card:hover:not(.consultation-card) .card-front {
  transform: rotateY(180deg); /* Rotate front side */
}

.card:hover:not(.consultation-card) .card-back {
  transform: rotateY(0); /* Show back side */
}

/* Keyframes for background color transition */
@keyframes changeColor {
  0% {
    background-color: #07a6a6;
  }
  50% {
    background-color: #004e4e; /* This will be overridden by JavaScript */
  }
  100% {
    background-color: #07a6a6;
  }
}

.consultation-card {
  background-color: #f0f8ff; /* Light color for distinction */
  border: 1px solid #ccc; /* Optional border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

@media only screen and (max-width: 767px) {
  .raw {
    text-align: center;
    padding: 10px;
    background-color: #a4b09c;
  }
  .raw h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 1rem;
    text-transform: uppercase;
  }
  .raw-t {
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .raw-p {
    font-size: 8px;
    width: 8rem;
    letter-spacing: 1px;
  }

  .raw-t2 {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .raw-t22 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .card {
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: white;
  }
  .consultation-card {
    width: 47vw;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: white;
  }
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 12px;
    justify-content: center;
    flex-wrap: wrap;
    justify-items: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  
  .card {
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: white;
}
.consultation-card {
  width: 47vw;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: white;
}
  .raw-p {
    font-size: 14px;
    width: 19rem;
    letter-spacing: 1px;
    font-family: sans-serif;
    font-weight: 500;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(331px, 2fr));
  gap: 10px;
  justify-content: center;
  justify-items: center;
}
  .raw-t2 {
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
}
.raw-t22 {
  font-size: 38px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
}





