.brands-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.brands-heading {
  font-size: 2.5rem;
  color: #004e4e;
  margin-bottom: 3rem;
  text-transform: uppercase;
  padding: 0.5rem;
  font-family: junge;
  font-weight: 600;
}

.brands-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brands-list {
  width: 65%;
  padding-right: 2rem;
}

.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 10px;
}


.product-item:hover {
  background-color: #a4b09c59;
}

.product-price {
  font-size: 1.5rem;
  color: #004e4e;
  width: 20%;
  text-align: left;
}

.product-desc {
  width: 60%;
  text-align: left;
}

.product-desc p {
  font-size: 21px;
  color: #005050;
  margin: 0;
  font-weight: 800;
  letter-spacing: 1px;
}

.product-desc h3 {
  font-size: 1.2rem;
  color: #8c7a67;
  margin-top: 0.5rem;
}

.product-index {
  font-size: 20px;
  color: #004e4e;
  width: 10%;
  text-align: right;
  font-weight: 800;
}

/* Right Side Image */
.product-image-container {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pnn {
  font-size: 20px;
  text-align: center;
  color: #004e4e;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 0;
  letter-spacing: 1px;
}

.prodiv {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 7px;
  justify-content: center;
}

.product-image11 {
  width: 80%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
  border-top-left-radius: 9em;
  border-top-right-radius: 9em;
  border-bottom-left-radius: 9em;
  border-bottom-right-radius: 9em;
  filter: drop-shadow(0px 0px 2px #004e4e);
}
.zoom-out {
  animation: zoomOut 0.5s ease-in-out;
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

.zoom-in {
  animation: zoomIn 0.5s ease-in-out;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* Hover Effect for Zoom-In and Zoom-Out */
.product-image11:hover {
  transform: scale(0.9); /* Zoom-out effect on hover */
}








@media only screen and (max-width: 767px){
  .brands-container {
    max-width: 1200px;
    margin: 0;
    padding: 1rem;
    text-align: center;
    padding-top: 0rem;
    padding-bottom: 3rem;
  }
  .brands-heading {
    font-size: 2rem;
    color: #004e4e;
    margin-bottom: 1rem;
    text-transform: uppercase;
    padding: 0.5rem;
  }
  .brands-content {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
}
.product-image-container {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pnn {
  font-size: 13px;
  text-align: center;
  color: #004e4e;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  letter-spacing: 1px;
}
.product-desc p {
  font-size: 14px;
  color: #005050;
  margin: 0;
  font-weight: 400;
  letter-spacing: 1px;
}
.brands-list {
  width: 100%;
  padding-right: 0rem;
}
.product-desc {
  width: 97%;
  text-align: left;
}
.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 10px;
}

.pnn {
  font-size: 12px;
  text-align: center;
  color: #004e4e;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  letter-spacing: 1px;
}
.product-index {
  font-size: 14px;
  color: #004e4e;
  width: 10%;
  text-align: right;
  font-weight: 700;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){

}
