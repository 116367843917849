/* Container for the entire section */
.product-section5 {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
 
  cursor: pointer;
  padding-left: 10px;
  margin-bottom: 1rem;
}
.cc-c {
  border: 1px solid black;
  PADDING: 10PX;
  HEIGHT: 41rem;
  border-radius: 46px;
  width: 24rem;
}
.key-benefits {
  margin-top: 10px;
  color: #010101;
}
.key-benefits li {
  font-size: 15px;
  font-weight: 400;
  font-family: sans-serif;
}

.key-benefits h4 {
  font-weight: bold;
  text-align: left;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-family: junge;
}

.key-benefits ul {
  list-style-type: disc;
  margin-left: 20px;
  text-align: left !important;
}

  .full-innnn{
    background-color:#a4b09c;
  }
  .incre-heading1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 0px;
    color: #010101;
    text-transform: uppercase;
    font-weight: 700;
    padding: 2rem;
    padding-top: 3.6rem;
    font-family: junge;
}
  
  /* Container for the product cards */
  .product-container5 {
    display: flex;
    gap: 14px;
    overflow-x: hidden;
    cursor: pointer;
    padding-bottom: 20px;
   
}
  
  .product-container5::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }
  .product-container5 {
    display: flex;
    gap: 14px;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding-bottom: 20px;
  
  }
  
  .product-container5::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }
  
  
  /* Individual product card */
  .product-card5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    min-width: 300px;
    flex-shrink: 0;
    background-color: #ffffff00;
}
  
.product-card5 h3 {
  margin: 10px 0;
  font-size: 1.2rem;
  color: #010101;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  font-family: junge;
}
  
.product-card5 p {
  color: #0d0d0d;
  margin-bottom: 0;
  font-size: 15px;
  text-align: justify;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.6;
  font-family: sans-serif;
}
  
  /* Container for the product image */
  .product-image5 {
    width: 262px;
    height: 232px;
    margin-bottom: 10px;
    mask: url('../../Assests/clip.svg');  /* Applying the SVG mask */
  -webkit-mask: url('../../Assests/clip.svg'); /* For better browser compatibility */

  mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    mask-size: contain;
    -webkit-mask-size: contain;
    overflow: hidden;
  }
  
  /* Styling for the product image itself */
  .product-image5 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
 















  @media only screen and (max-width: 767px){
    .incre-heading1 {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 0px;
      color: #010101;
      text-transform: uppercase;
      font-weight: 700;
      padding: 2rem;
      padding-top: 3rem;
  }
  .cc-c {
    border: 1px solid black;
    PADDING: 10PX;
    HEIGHT: 45rem;
    border-radius: 46px;
    width: 21rem;
}
  .product-section5 {
    overflow-x: hidden;
    cursor: pointer;
  }

.product-container5 {
  display: flex;
  gap: 14px;
  overflow-x: auto;
  cursor: pointer;
  scroll-behavior: smooth;
  padding-bottom: 20px;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .cc-c {
      border: 1px solid black;
      PADDING: 10PX;
      HEIGHT: 45rem;
      border-radius: 46px;
      width: 21rem;
  }
  }
