
.chat-component {
  position: fixed;
  bottom: 100px;
  right: -25px;
  background-color:#a4b09c;
  border: 2px solid #fdfdfd;
  border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  padding: 10px;
  transition: transform 0.3s ease-in;
  z-index: 4999;
  width: 7rem;
  overflow: hidden;
}
.chat-component {
  position: fixed;
  bottom: 193px;
  right: -25px;
  background-color: #a4b09e9e;
  border: 2px solid #004e4e;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
  transition: transform 0.3s ease-in;
  z-index: 4999;
  width: 5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.icon-chat {
  width: 2rem;
  height: auto;
  color: #004e4e;
  cursor: pointer;
  transition: transform 0.3s ease;
  filter: drop-shadow(0px 0px 2px white);
}
.icon-chat:hover{
  transform: rotate(30deg);
}

 
 


  @media only screen and (max-width: 767px){
    .chat-component {
      position: fixed;
      bottom: 134px;
      background-color: #a4b09c;
  border: 1px solid #004e4e;
      border-radius: 28px;
      padding: 10px;
      transition: transform 0.3s ease-in;
      right: -36px;
  }
  .icon-chat{
    width: 1.5rem;
    height: auto;
    color: #004e4e;
    cursor: pointer;
    filter: drop-shadow(0px 0px 2px white);
  transition: transform 0.3s ease;
  }
  .icon-chat:hover{
    transform: rotate(30deg);
  }
  .chat-component2 {
    position: fixed;
    bottom: 80px;
    right: -65px;
    background-color: #a4b09c;
    border: 2px solid #fdfdfd;
    border-radius: 28px;
    padding: 10px;
    transition: transform 0.3s ease-in;
}
  }